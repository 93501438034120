@import 'variables';
@import 'helpers';

.main-content .field-loader {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 100;
    transform: translate(-50%, -50%);
}

.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  /* The actual tooltip */
  .tooltip-text {
    visibility: hidden;
    width: 140px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;

    /* Positioning the tooltip */
    position: absolute;
    bottom: 125%; /* Position it above the element */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  /* Tooltip arrow */
  .tooltip-text::after {
    content: '';
    position: absolute;
    top: 100%; /* Position arrow at the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  /* Show the tooltip when hovering over the container */
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
  }

  /* For mobile, use a class to show the tooltip */
  .show-tooltip .tooltip-text {
    visibility: visible;
    opacity: 1;
  }


.fields-container {
    display: flex;
    height: auto;
    background-color: #ffffff;

    @include break($laptop-screen) {
        min-height: 700px;
    }

    @include break($mobile-screen) {
        min-height: 500px;
        display: block;
    }

    @include break($mobile-screen) {
        .main-content {
            padding-top: 20px;
            padding-left: 20px;

            .farms-owner-header {
                font-size: 18px !important;
            }
        }
    }
}

.manage-field-content {
    width: 100%;
    position: relative;

    .ruler {
        height: 1px;
        border: 1px solid #EEEEEE;
        margin-right: 50px;
        margin-bottom: 40px;
        width: auto;
    }


    .add-field {
        float: right;
        position: absolute;
        right: 40px;
        top: 25px;
        cursor: pointer;

        .add-icon {
            display: inline-block;
            vertical-align: middle;
            color: $card-new-icon;

            svg {
                width: 23px;
                height: 23px;
            }
        }

        a {
            color: $general-text-color;
        }

        @include break($laptop-screen) {
            position: absolute;
            margin-top: 5px;
        }

        @include break($mobile-screen) {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            float: none;
            right: 0;
            top: 0;
            margin: 10px 20px 0px;
        }
    }

    .pad-right {
        padding-right: 5px;
    }

    .no-fields-text {
        padding: 50px 30px;
    }

    .field-list-container>div {
        grid-template-columns: minmax(35rem, 1fr) 0 0;
        -ms-grid-columns: minmax(35rem, 1fr) 0 0;

        @include break($mobile-screen) {
            grid-template-columns: minmax(30rem, 1fr) 0 0;
            -ms-grid-columns: minmax(35rem, 1fr) 0 0;
        }
    }
}

.field-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .list-item {
        .field-details {
            display: inline-flex;
            flex-basis: 100%;
            width: 100%;

            .field-crop,
            .field-size,
            .field-image {
                border-right: 1px solid #BEC8C9;
                padding: 0 15px;
                max-width: 12%;
                min-width: 12%;
                padding-top: 0.8%;
            }

            .field-crop,
            .field-size {
                width: 15%;

                .item-value {
                    max-width: 15%;
                }
            }

            .field-age {
                border-right: 1px solid $common-bdr-color;
                padding: 0 15px;
                max-width: 150px;
                min-width: 80px;
            }

            .field-manure {
                border-right: 1px solid $common-bdr-color;
                padding: 0 15px;
                max-width: 150px;
                min-width: 100px;
            }

            .field-yield {
                border-right: 1px solid $common-bdr-color;
                padding: 0 15px;
                max-width: 150px;
                min-width: 100px;
            }

            .field-harvest {
                padding: 0 15px;
                max-width: 8%;
                min-width: 8%;
                padding-top: 0.8%;
            }

            .field-delete-last {
                padding-top: 0.8%;
                min-width: 12px;
                max-width: 12px;
                padding-right: 3%;
            }

            .field-avling {
                border-right: 1px solid #BEC8C9;
                padding: 0 15px;
                max-width: 16%;
                min-width: 16%;
                padding-top: 0.8%;
            }

            .field-size {
                border-right: 0;
            }

            .field-titlecard {
                width: 36%;
                padding-right: 10px;
            }

            .field-tempsum {
                color: #666666;
                border-left: 1px solid #eee;
                text-align: right;
                width: 9%;
                padding-top: 16px;
                padding-right: 10px;
            }

            .field-tempsum a {
                color: #111111;
            }

            .field-image {
                min-width: 28%;
                display: flex;
                align-items: center;
                padding-left: 0;

                .field-tile {
                    float: right;
                    width: calc(100% - 85px);
                }

                .field-img {
                    float: left;
                    height: 65px;
                    width: 65px;
                }

                .field-name {
                    float: right;
                    font-weight: $font-light;
                    margin: 23px 15px 0 0;
                    text-align: right;
                    height: 20px;
                    width: calc(100% - 85px);
                }
            }

            @include break($mobile-screen) {
                display: flex;
                position: relative;

                .field-image {
                    min-width: 50%;
                }

                .field-crop,
                .field-size {
                    max-width: 15%;

                    .item-value {
                        width: 100%;
                    }
                }

                .field-titlecard {
                    max-width: 25%;
                    padding-right: 10px;
                }

                .field-tempsum {
                    max-width: 20%;

                    .item-value {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.edit-field-textcontainer {
    display: flex;
    background: #fff;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0px 25px;

    .field-area {
        flex: 0 0 100%;
        width: 100%;
        min-width: 300px;
        box-sizing: border-box;
        background-color: #fff;
        padding: 15px;
        border: 1px solid #e1e1e1;
        text-align: justify;

        .p {
            padding-bottom: 10px;
        }

        .padding {
            padding-bottom: 20px;
            line-height: 26px;

            a {
                color: #a1a1a1;
                text-decoration: underline;
            }
        }

        .bold {
            font-weight: 800;
        }

        .ul-padding {
            padding-left: 40px;
        }

        .li-list-style {
            list-style: disc;
        }
    }
}

.weather-checkbox {
    margin-bottom: 22px;
}

.field-prev-css {
    padding-right: 50px;
}

.field-prev-next-css {
    padding-top: 12px;
    padding-left: 35%;
}

.field-prev-next-css1 {
    padding-top: 10px;
    padding-left: 35%;
}

.field-prev-next-css2 {
    padding-top: 12px;
    //padding-left: 38%;
}

.field-prev-next-css2-mobile {
    padding-top: 90px;
}

.field-prev-next-link {
    cursor: pointer;
    color: #000;
    background: none;
    border: none;
    text-decoration: underline;
    padding: 0;
    font-size: 20px;
}

.field-prev-next-link-disable {
    color: #666666;
    font-size: 20px;
    background: none;
    border: none;
}

.field-prev-next-link-mobile {
    cursor: pointer;
    color: #000;
    background: none;
    border: none;
    text-decoration: underline;
    padding: 0;
    font-size: 14px;
}

.field-prev-next-link-disable-mobile {
    color: #666666;
    font-size: 14px;
    background: none;
    border: none;
}

.prev-next-float-left {
    float: left;
}

.smallWidth-mui {
    width: 20%;
}