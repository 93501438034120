/* Default outline color */
.MuiOutlinedInput-notchedOutline {
  border-color: #6f7979 !important;
  border-width: 1px !important;
}

/* On focus: change outline color and thickness */
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #00696d !important;
  border-width: 2px !important;
}

/* Remove spacing between the icon and the text */
.MuiOutlinedInput-adornedEnd {
  padding-right: 0 !important;
}

.customDatePickerWrapper .react-datepicker-wrapper .react-datepicker__input-container input {
  border: none !important;
}
