.tasks-container {
    display: flex;
    height: auto;
    min-height: 457px;

    .main-content {
        padding-top: 60px;
        padding-left: 42px;
        width: 100%;
        position: relative;

        .add-task {
            float: right;
            position: absolute;
            right: 40px;
            top: 25px;
            cursor: pointer;

            span {
                padding-right: 5px;
            }

            .add-icon {
                display: inline-block;
                vertical-align: middle;
                color: $card-new-icon;

                svg {
                    width: 23px;
                    height: 23px;
                }
            }

            @include break($laptop-screen) {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                float: none;
                right: 0;
                top: 0;
                margin: 10px 20px 0px;
            }
        }

        .tasks-grid {}
    }
}

.task-margin-top {
    margin-top: 4px;
}

.padding-right-user-dropdown {
    padding-right: 15px !important;
}

.task-date-picker {
    height: 40px !important;
    width: 150px !important;
}

.navigation-image-flex {
    display: flex;
}

.navigation-image-flex-div {
    padding-left: 14px;
}

.navigation-image-flex-div-1 {
    padding-left: 8px;
    padding-top: 2px;
}