@import 'variables';
@import 'helpers';

.footer {
    bottom: 0;
    left: 0;
    width: 100%;
    position: absolute;
    padding-bottom: 16px;
    background-color: #f2f4f4;

    .footer-privacy-policy {
        height: 103px;
        background-color: $footer-bg;
        display: flex;
        align-items: stretch;

        .no-content {
            flex: 5;
        }

        .privacy-policy {
            padding: 61px 15px 25px 0px;
            text-align: right;
            min-width: 110px;
            font-size: 12px;
            line-height: 17px;
            /*flex-grow: 1;*/
            white-space: nowrap;
            flex-basis: auto;

            a {
                color: $general-text-color;
            }
        }

        .footer-right-content {
            padding-right: 42px;
            padding-top: 59px;
            padding-bottom: 25px;
            padding-left: 25px;
            text-align: right;
            /*flex: 1;*/

            span:nth-child(1) {
                height: 17px;
                width: 13px;
                color: $footer-italic-color;
                font-size: 12px;
                font-style: italic;
                line-height: 17px;
            }

            span:nth-child(2) {
                font-size: 19px;
                vertical-align: middle;
            }
        }
    }

    .footer-contact-details {
        height: 190px;
        background-color: $footer-contact-details-bg;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
        display: flex;

        .no-content {
            flex: 5;
        }

        .footer-contacts {
            flex: 1;
            text-align: right;
            padding-top: 35px;
            font-size: 14px;
            line-height: 19px;
            font-weight: 600;

            p:not(:nth-child(1)) {
                color: $link-color;
            }

            p:nth-child(2) {
                padding: 9px 0px;
            }

            p:nth-child(3) {
                a {
                    text-decoration: none;
                    color: inherit;
                    transition: all 0.5s;
                }

                a:hover {
                    color: $footer-link-hover;
                }
            }
        }

        .footer-address {
            flex: 1;
            text-align: right;
            padding: 35px 43px 42px 0px;
            font-size: 12px;
            line-height: 17px;

            p:nth-child(1) {
                font-size: 14px;
                font-weight: 600;
                line-height: 19px;
                margin-bottom: 7px;
            }

            span:nth-child(4) {
                padding-top: 10px;
            }
        }
    }

    @include break($mobile-screen) {
        bottom: 60px;
        padding-bottom: 20px;
        .footer-privacy-policy {
            flex-direction: column;
            align-items: center;

            .privacy-policy {
                padding: 5px 0px 5px;
                text-align: center;
            }

            .footer-right-content {
                padding-top: 10px;
                padding-right: 0px;
                padding-left: 0px;
            }
        }

        .footer-contact-details {
            flex-direction: column;
            align-items: center;
            height: 215px;

            .no-content {
                display: none;
            }

            .footer-contacts {
                text-align: center;
                padding-top: 10px;

                p:nth-child(2) {
                    padding: 5px 0px;
                }
            }

            .footer-address {
                padding: 15px 0px;
                text-align: center;

                span:nth-child(4) {
                    padding-top: 0px !important;
                    padding-bottom: 10px;
                }
            }
        }
    }
}

.toc_wrapper {
    color: $general-text-color;

    span:first-of-type {
        display: none;
    }

    .tc-content {
        min-height: 300px;

        span:first-of-type {
            display: initial;
        }

        p,
        li {
            font-size: 12px;
            font-weight: normal;
        }

        li {
            padding: 20px 0 0;
        }

        p {
            strong {
                font-size: 12px;
                font-weight: $font-semi-bold;
                padding: 20px 0 10px;
                float: left;
                ;
                width: 100%;
            }
        }

        a {
            @extend .link;
        }

        h1 {
            font-size: 14px;
            font-weight: $font-semi-bold;
            margin-bottom: 20px;
            text-transform: uppercase;
        }
    }

    .btn-wapper {
        margin: 35px 0;
        text-align: center;
        @extend .full-width;

        button {
            margin: 15px 15px 0;
        }
    }
}

.span-cursor {
    cursor: pointer;
}