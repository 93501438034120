@import 'variables';
@import 'helpers';

.stats-header {
  border: 1px solid #2A3C63;

  .items-container {
    display: flex;

    .item {
      border-right: 1px solid #ccc;
      color: $general-text-color;
      padding: 3px 20px;
      text-align: center;

      i {
        font-size: 1.5rem;
      }

      >span {
        display: block;
        font-size: 0.75rem;
        margin-top: 0.5rem;

        span {
          float: right;
          margin: -1px 0 0 5px;
        }
      }

      &:nth-child(2) {
        i {
          font-size: 1.9rem;
        }

        >span {
          margin-top: 0.1rem;
        }
      }

    }

    @include untilTablet() {
      flex-wrap: wrap;

      .item {
        width: 50%;
        margin-bottom: 20px;

        >span span {
          float: unset;
        }

        &:nth-child(even) {
          border: none;
        }
      }
    }
  }
}

.stats-activity {

  [class^="styled-components__FlexGridWrapper"],
  [class*=" styled-components__FlexGridWrapper"] {
    overflow: visible;
  }

  .stats-activity__grid {
    padding: 15px 0;
  }

  .stats-activity__textalign {
    text-align: right;
  }
}

.div-kpi-box {
  margin: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
  grid-gap: 1.5rem;
  padding-top: 17px;

  @include break($mobile-screen) {
    grid-template-columns: none;
  }
}

.kpi-box {
  border: 1px solid $kpi-bdr-color;
  border-left: 4px solid $kpi-bdr-l-color;
  display: flex;
  flex-flow: column;
  height: 240px;
  /*min-width: 50%;*/
  padding: 10px 15px 0;

  .kpi-box__title {
    flex: 1;
    align-self: flex-start;
    width: 100%;

    >span {
      font-size: 17px;
      font-family: 'Inter';
    }

    &.kpi-box__title--has-filter {
      >span {
        width: 50%;
        float: left;
        font-size: 17px;
        font-family: 'Inter';
      }
    }
  }

  &.kpi-box--active {
    border-left: 4px solid $kpi-bdr-l-hcolor;
  }

  &.kpi-box--users {
    padding-right: 0;

    .kpi-box__title {
      flex: initial;
      padding-bottom: 20px;
    }
  }

  .select-box-common {
    float: left;
    width: 50%;

    .css-bg1rzq-control,
    .css-14ojxsb-control,
    .css-1szy77t-control {
      height: 40px;
    }
  }

  .select-box-field {
    float: left;
    width: 50%;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;

    .css-bg1rzq-control,
    .css-14ojxsb-control,
    .css-1szy77t-control {
      height: 40px;
    }
  }
}

.kpi-box-nitrogen {
  display: flex;
  flex-flow: column;
  min-height: 200px;
  min-width: 50%;
}

.kpi-info__text {
  >span {
    font-size: 16px;
    font-family: 'Inter';
  }
}

.kpi-add {
  flex: 1;
  text-align: center;

  .kpi-add__text {
    padding-bottom: 5px;

    >span {
      font-size: 15px;
      font-family: 'Inter';
    }
  }
}

.kpi-info {
  align-self: flex-end;
  width: 100%;

  .kpi-info__no {
    float: left;
    width: 100%;
    word-wrap: break-word;

    &.kpi-info__no--has-filter {
      width: 50%;
    }

    & span:nth-child(2) {
      margin-left: 10px;
    }
  }

  .kpi-info__no_1 {
    float: left;
    width: 100%;
    word-wrap: break-word;

    &.kpi-info__no--has-filter {
      width: 50%;
    }
  }
}

.kpi-user-list {
  max-height: 320px;
  overflow-y: auto;
  padding-right: 15px;

  li {
    border-top: 1px solid $kpi-bdr-user;
    padding: 7px 0;

    >span {
      float: left;

      &:last-child {
        float: right;
      }
    }
  }

  .profile-img {
    float: left;
    margin: 2px 5px 0 0;
  }
}

/**
* ie 11 fix
*/
_:-ms-fullscreen,
:root {
  .kpi-box {
    .kpi-box__title {
      padding-bottom: 30px;
    }
  }
}

.stats_header_class {
  padding: 0.5rem 2.5rem 0.5rem 2.5rem !important;
}

.stats_header_class2 {
  padding: 0 2.5rem 0.5rem 2.5rem !important;
}

.stats_filter_class {
  padding: 10px 40px 15px 0px !important;
}

.stats-activity__filter {
  float: left;
  padding-left: 20px;
}

.stats-unit-font-size {
  font-size: 20px;
}

.float-left-css-kpi {
  float: left;
}