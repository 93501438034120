@import 'variables';
@import 'helpers';

.btn-action {

  button,
  a {
    background-color: #FFFFFF;
    box-shadow: 0 2px 15px 0 rgba(183, 183, 183, 0.5);
    border-radius: 50%;
    display: inline-block;
    padding: 3px;
    line-height: 9px;
  }

  &.disabled {
    button {
      background-color: $disable-color !important;

      svg {
        fill: $white;
      }
    }
  }

  &.btn-action--add {
    a {
      color: $positive;
      fill: $positive;

      &:hover,
      &:active {
        color: $positive-shade-light;
        fill: $positive-shade-light;
      }
    }
  }

  &.btn-action--delete {

    button {
      background-color: $negative;
      fill: $white;
      border: 3px solid $white;

      div {
        height: auto;
        padding: 2.2px 3.5px;
        width: auto;
      }

      &:hover {
        background-color: $negative-shade-light;
      }

      &:active {
        background-color: $negative-dark;
      }
    }
  }

  &.btn-action--help {
    button {
      background-color: $secondary;
      border: 3px solid $white;
      color: $white;
      padding: 0;
      font-family: $font-sans;
      font-weight: $font-semi-bold;
      font-size: 18px;

      div {
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
      }

      &:hover {
        background-color: $secondary-shade-medium;
      }

      &:active {
        background-color: $secondary-shade-dark;
      }
    }
  }
}