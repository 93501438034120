.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-color: #209fd5;
  border-style: dashed;
  background-color: #e8f5fa;
  color: #000;
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 185px;
  p {
    padding-bottom: 30px;
  }
  &.drag-reject {
    border-color: $negative;
    background-color: rgba(238, 17, 72, 0.4);
    &.drag-active {
      border-width: 2px;
    }
  }
  &.drag-active {
    border-width: 4px;
  }
}

.upload-msg-box {
  [class^='styled-components__GridLayout'],
  [class*=' styled-components__GridLayout'] {
    grid-template-columns: 1fr minmax(360px, 300px) 1fr;
  }
  .completed-upload {
    max-width: 320px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
      margin: 0 0 20px;
    }
    button {
      margin-top: 30px;
      min-width: 100px;
      min-height: 40px;
    }
    &.upload-error svg circle {
      fill: $negative;
    }
  }
}
.progress-bar {
  text-align: center;
}
.progress-bar-container {
  background: #2a3c63;
  border-radius: 2.5px;
  height: 5px;
  margin: 0 auto;
  position: relative;
  width: 242px;
}
.animate-progres {
  background: #209fd5;
  border-radius: 2.5px;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
  -webkit-transition: width 1s;
  -moz-transition: width 1s;
  -o-transition: width 1s;
  transition: width 1s;
}

.soil-sample-button-style{
  padding-top: 15px;
  text-align: center;
}

.soil-sample-button-style-before{
  padding-top: 95px;
  text-align: center;
}

.soil-sample-field {
  margin-top: 40px;
  .form-group label.custom-lbl {
    font-size: 1.0rem !important;
    margin-bottom: 1.0rem !important;
  }
}
