@import 'variables';
@import 'helpers';

.balesFlexiGrid .styled-components__FlexGridSection-h31gbc-1 {
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr)) !important;
}

.balesColumnFlexiGrid .styled-components__FlexGridSection-h31gbc-1 {
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr)) !important;
}

.align-center-span {
  text-align: center;
}

.floatLeft {
  float: left;
}

.floatRight {
  float: right;
}

.paddingRight {
  padding-right: 20px;
}

.paddingRight-less {
  padding-right: 10px;
}

.add-harvest {
  cursor: pointer;
  float: right;
  margin-top: -2px;
  margin-left: 3%;

  .add-icon {
    display: inline-block;
    vertical-align: middle;
    color: $card-new-icon;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .cross-icon {
    display: inline-block;
    vertical-align: middle;
    color: $card-new-icon;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  a {
    color: $general-text-color;
  }
}

.delete-harvest {
  cursor: pointer;
  float: right;

  .delete-icon {
    display: inline-block;
    vertical-align: middle;
    color: $card-new-icon;

    svg {
      width: 15px;
      height: 15px;
    }
  }

  a {
    color: $general-text-color;
  }
}

.storage-box {
  display: flex;
  flex-flow: column;
  //min-height: 150px;
  padding: 15px;

  .storage-box__title {
    flex: 1;
    align-self: flex-start;
    width: 100%;
    padding-bottom: 10px;

    &.storage-box__title--has-filter {
      >span {
        width: 50%;
        float: left;
      }
    }
  }

  &.storage-box--active {
    border-left: 4px solid $kpi-bdr-l-hcolor;
  }

  &.storage-box--users {
    padding-right: 0;

    .storage-box__title {
      flex: initial;
    }
  }

  .select-box-common {
    float: left;
    width: 50%;

    .css-bg1rzq-control,
    .css-14ojxsb-control,
    .css-1szy77t-control {
      height: 100%;
    }
  }

  .select-box-field {
    float: left;
    width: 50%;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;

    .css-bg1rzq-control,
    .css-14ojxsb-control,
    .css-1szy77t-control {
      height: 40px;
    }
  }
}

.storage-list-details {
  .storage-list-wrapper {
    display: flex;
    flex-wrap: wrap;

    .storage-list-item {
      .storage-details {
        display: inline-flex;
        flex-basis: 100%;
        width: 100%;
        vertical-align: middle;

        .storage-arrow,
        .storage-number {
          align-self: center;
          border-right: 0;
        }

        .storage-arrow {
          flex: 1;
        }

        .storage-number {
          text-align: right;
          width: 10%
        }

        .add-storage-arrow {
          transform: rotate(90deg);
          font-size: 14px;
          float: right;
          margin-top: 9px;
        }

        .scheduled-storage-arrow {
          transform: rotate(90deg);
          font-size: 14px;
          float: right;
          margin-top: 6px;
        }

        .add-storage-arrow-label {
          transform: rotate(90deg);
          font-size: 14px;
          float: right;
        }

        .storage-name,
        .storage-number {
          .item-value {
            font-weight: 300;
          }
        }

        .storage-name {
          .item-lbl {
            font-size: 14px;
          }

          span {
            width: 100%;
          }
        }

        @include break($laptop-screen) {
          display: flex;
          position: relative;

          .storage-name,
          .storage-number,
          .storage-arrow {
            min-width: 100px;
          }

          .edit-bale-container {
            .field-area {
              flex: 0 0 54%;
              width: 54%;
            }

            .bale-settings {
              flex: 0 0 45%;
              width: 45%;
            }
          }
        }

        @include break($mobile-screen) {

          .storage-name,
          .storage-number,
          .storage-arrow {
            min-width: 30px;
          }

          .edit-bale-container {
            flex-flow: column wrap;

            .field-area {
              flex-grow: 1;
              width: 100%;
            }

            .bale-settings {
              width: 100%;
              padding-bottom: 20px;

              .bale-settings-form {
                padding: 10px 20px 0px 20px;
              }
            }
          }
        }
      }

      .storage-list-item-link {
        border-left: 4px solid #aeaeae;
        width: 100%;
      }

      .storage-list-item-div {
        border-left: 1px solid #aeaeae;
        width: 100%;
      }

      .storage-list-item-scheduler {
        border: 1px solid #aeaeae;
        background-color: #fffcea;
        width: 100%;
        cursor: pointer;
      }
    }

    @include break($laptop-screen) {
      padding: 20px 10px;
    }
  }
}

.add-bale-header {
  font-size: 18px;
}

.add-bales-arrow-label {
  padding-top: 8px;
  transform: rotate(90deg);
  font-size: 14px;
  float: right;
}

.padding-bottom-border {
  padding-bottom: 20px;
  border-bottom: 1px solid #cccccc;
}

.modal-storage-list-details {
  .storage-list-wrapper {
    display: flex;
    flex-wrap: wrap;

    .modal-storage-list-item1 {
      .storage-details {
        display: inline-flex;
        flex-basis: 100%;
        width: 100%;
        vertical-align: middle;
        border: 1px solid #cccccc;
        padding: 5px 5px;

        .storage-arrow-text .storage-arrow,
        .storage-number {
          align-self: center;
          border-right: 0;
        }

        .storage-arrow-text {
          width: 26%;
          padding-top: 2px;
        }

        .storage-arrow {
          width: 10%;
        }

        .storage-arrow-omd {
          padding-right: 10px;
        }

        .add-storage-arrow {
          font-size: 12px;
          float: right;
          margin-top: 9px;
        }

        .scheduled-storage-arrow {
          font-size: 12px;
          float: right;
          margin-top: 6px;
        }

        .add-storage-arrow-label {
          font-size: 12px;
          float: right;
        }

        .storage-name,
        .storage-number {
          .item-value {
            font-weight: 300;
          }
        }

        .storage-name {
          .item-lbl {
            font-size: 14px;
          }

          span {
            width: 100%;
          }
        }

        @include break($laptop-screen) {
          display: flex;
          position: relative;

          .storage-name,
          .storage-number,
          .storage-arrow {
            min-width: 100px;
          }

          .edit-bale-container {
            .field-area {
              flex: 0 0 54%;
              width: 54%;
            }

            .bale-settings {
              flex: 0 0 45%;
              width: 45%;
            }
          }
        }

        @include break($mobile-screen) {

          .storage-name,
          .storage-number,
          .storage-arrow {
            min-width: 30px;
          }

          .edit-bale-container {
            flex-flow: column wrap;

            .field-area {
              flex-grow: 1;
              width: 100%;
            }

            .bale-settings {
              width: 100%;
              padding-bottom: 20px;

              .bale-settings-form {
                padding: 10px 20px 0px 20px;
              }
            }
          }
        }
      }

      .storage-details-agreement {
        display: inline-flex;
        flex-basis: 100%;
        width: 100%;
        vertical-align: middle;
        border-bottom: 1px solid #f1f1f1;
        padding: 10px 5px;

        .storage-arrow-text .storage-arrow,
        .storage-number {
          align-self: center;
          border-right: 0;
        }

        .storage-arrow-text {
          width: 26%;
          padding-top: 2px;
        }

        .storage-arrow {
          width: 10%;
        }

        .add-storage-arrow {
          font-size: 12px;
          float: right;
          margin-top: 9px;
        }

        .scheduled-storage-arrow {
          font-size: 12px;
          float: right;
          margin-top: 6px;
        }

        .add-storage-arrow-label {
          font-size: 12px;
          float: right;
        }

        .storage-name,
        .storage-number {
          .item-value {
            font-weight: 300;
          }
        }

        .storage-name {
          .item-lbl {
            font-size: 14px;
          }

          span {
            width: 100%;
          }
        }

        @include break($laptop-screen) {
          display: flex;
          position: relative;

          .storage-name,
          .storage-number,
          .storage-arrow {
            min-width: 100px;
          }

          .edit-bale-container {
            .field-area {
              flex: 0 0 54%;
              width: 54%;
            }

            .bale-settings {
              flex: 0 0 45%;
              width: 45%;
            }
          }
        }

        @include break($mobile-screen) {

          .storage-name,
          .storage-number,
          .storage-arrow {
            min-width: 30px;
          }

          .edit-bale-container {
            flex-flow: column wrap;

            .field-area {
              flex-grow: 1;
              width: 100%;
            }

            .bale-settings {
              width: 100%;
              padding-bottom: 20px;

              .bale-settings-form {
                padding: 10px 20px 0px 20px;
              }
            }
          }
        }
      }


      .storage-details-no-width {
        display: inline-flex;
        flex-basis: 100%;
        width: 100%;
        vertical-align: middle;
        border-bottom: 1px solid #cccccc;
        padding: 10px 5px;

        .storage-arrow-text .storage-arrow,
        .storage-number {
          align-self: center;
          border-right: 0;
        }

        .storage-arrow-text {
          width: 26%;
          padding-top: 2px;
        }

        .storage-arrow {
          width: 10%;
        }

        .add-storage-arrow {
          font-size: 12px;
          float: right;
          margin-top: 9px;
        }

        .scheduled-storage-arrow {
          font-size: 12px;
          float: right;
          margin-top: 6px;
        }

        .add-storage-arrow-label {
          font-size: 12px;
          float: right;
        }

        .storage-name,
        .storage-number {
          .item-value {
            font-weight: 300;
          }
        }

        .storage-name {
          .item-lbl {
            font-size: 14px;
          }
        }

        @include break($laptop-screen) {
          display: flex;
          position: relative;

          .storage-name,
          .storage-number,
          .storage-arrow {
            min-width: 100px;
          }

          .edit-bale-container {
            .field-area {
              flex: 0 0 54%;
              width: 54%;
            }

            .bale-settings {
              flex: 0 0 45%;
              width: 45%;
            }
          }
        }

        @include break($mobile-screen) {

          .storage-name,
          .storage-number,
          .storage-arrow {
            min-width: 30px;
          }

          .edit-bale-container {
            flex-flow: column wrap;

            .field-area {
              flex-grow: 1;
              width: 100%;
            }

            .bale-settings {
              width: 100%;
              padding-bottom: 20px;

              .bale-settings-form {
                padding: 10px 20px 0px 20px;
              }
            }
          }
        }
      }

      .storage-list-item-link {
        border-left: 4px solid #aeaeae;
        width: 100%;
      }

      .storage-list-item-div {
        border-left: 1px solid #aeaeae;
        width: 100%;
      }
    }

    .modal-storage-list-item {
      .storage-details {
        display: inline-flex;
        flex-basis: 100%;
        width: 100%;
        vertical-align: middle;
        border: 1px solid #cccccc;
        padding: 10px 5px;

        .storage-arrow-text .storage-arrow,
        .storage-number {
          align-self: center;
          border-right: 0;
        }

        .storage-arrow-text {
          width: 26%;
          padding-top: 2px;
        }

        .storage-arrow {
          width: 10%;
        }

        .storage-arrow-omd {
          padding-right: 10px;
        }

        .add-storage-arrow {
          font-size: 12px;
          float: right;
          margin-top: 9px;
        }

        .scheduled-storage-arrow {
          font-size: 12px;
          float: right;
          margin-top: 6px;
        }

        .add-storage-arrow-label {
          font-size: 12px;
          float: right;
        }

        .storage-name,
        .storage-number {
          .item-value {
            font-weight: 300;
          }
        }

        .storage-name {
          .item-lbl {
            font-size: 14px;
          }

          span {
            width: 100%;
          }
        }

        @include break($laptop-screen) {
          display: flex;
          position: relative;

          .storage-name,
          .storage-number,
          .storage-arrow {
            min-width: 100px;
          }

          .edit-bale-container {
            .field-area {
              flex: 0 0 54%;
              width: 54%;
            }

            .bale-settings {
              flex: 0 0 45%;
              width: 45%;
            }
          }
        }

        @include break($mobile-screen) {

          .storage-name,
          .storage-number,
          .storage-arrow {
            min-width: 30px;
          }

          .edit-bale-container {
            flex-flow: column wrap;

            .field-area {
              flex-grow: 1;
              width: 100%;
            }

            .bale-settings {
              width: 100%;
              padding-bottom: 20px;

              .bale-settings-form {
                padding: 10px 20px 0px 20px;
              }
            }
          }
        }
      }

      .storage-details-agreement {
        display: inline-flex;
        flex-basis: 100%;
        width: 100%;
        vertical-align: middle;
        border-bottom: 1px solid #f1f1f1;
        padding: 10px 5px;

        .storage-arrow-text .storage-arrow,
        .storage-number {
          align-self: center;
          border-right: 0;
        }

        .storage-arrow-text {
          width: 26%;
          padding-top: 2px;
        }

        .storage-arrow {
          width: 10%;
        }

        .add-storage-arrow {
          font-size: 12px;
          float: right;
          margin-top: 9px;
        }

        .scheduled-storage-arrow {
          font-size: 12px;
          float: right;
          margin-top: 6px;
        }

        .add-storage-arrow-label {
          font-size: 12px;
          float: right;
        }

        .storage-name,
        .storage-number {
          .item-value {
            font-weight: 300;
          }
        }

        .storage-name {
          .item-lbl {
            font-size: 14px;
          }

          span {
            width: 100%;
          }
        }

        @include break($laptop-screen) {
          display: flex;
          position: relative;

          .storage-name,
          .storage-number,
          .storage-arrow {
            min-width: 100px;
          }

          .edit-bale-container {
            .field-area {
              flex: 0 0 54%;
              width: 54%;
            }

            .bale-settings {
              flex: 0 0 45%;
              width: 45%;
            }
          }
        }

        @include break($mobile-screen) {

          .storage-name,
          .storage-number,
          .storage-arrow {
            min-width: 30px;
          }

          .edit-bale-container {
            flex-flow: column wrap;

            .field-area {
              flex-grow: 1;
              width: 100%;
            }

            .bale-settings {
              width: 100%;
              padding-bottom: 20px;

              .bale-settings-form {
                padding: 10px 20px 0px 20px;
              }
            }
          }
        }
      }


      .storage-details-no-width {
        display: inline-flex;
        flex-basis: 100%;
        width: 100%;
        vertical-align: middle;
        border-bottom: 1px solid #cccccc;
        padding: 10px 5px;

        .storage-arrow-text .storage-arrow,
        .storage-number {
          align-self: center;
          border-right: 0;
        }

        .storage-arrow-text {
          width: 26%;
          padding-top: 2px;
        }

        .storage-arrow {
          width: 10%;
        }

        .add-storage-arrow {
          font-size: 12px;
          float: right;
          margin-top: 9px;
        }

        .scheduled-storage-arrow {
          font-size: 12px;
          float: right;
          margin-top: 6px;
        }

        .add-storage-arrow-label {
          font-size: 12px;
          float: right;
        }

        .storage-name,
        .storage-number {
          .item-value {
            font-weight: 300;
          }
        }

        .storage-name {
          .item-lbl {
            font-size: 14px;
          }
        }

        @include break($laptop-screen) {
          display: flex;
          position: relative;

          .storage-name,
          .storage-number,
          .storage-arrow {
            min-width: 100px;
          }

          .edit-bale-container {
            .field-area {
              flex: 0 0 54%;
              width: 54%;
            }

            .bale-settings {
              flex: 0 0 45%;
              width: 45%;
            }
          }
        }

        @include break($mobile-screen) {

          .storage-name,
          .storage-number,
          .storage-arrow {
            min-width: 30px;
          }

          .edit-bale-container {
            flex-flow: column wrap;

            .field-area {
              flex-grow: 1;
              width: 100%;
            }

            .bale-settings {
              width: 100%;
              padding-bottom: 20px;

              .bale-settings-form {
                padding: 10px 20px 0px 20px;
              }
            }
          }
        }
      }

      .storage-list-item-link {
        border-left: 4px solid #aeaeae;
        width: 100%;
      }

      .storage-list-item-div {
        border-left: 1px solid #aeaeae;
        width: 100%;
      }
    }

    @include break($laptop-screen) {
      padding: 20px 10px;
    }
  }
}

.label-header {
  padding-top: 4px;
}

.label-number {
  font-size: 17px;
  font-weight: 600;
}

.label-number-small {
  font-weight: 600 !important;
}

.label-number-bold {
  font-size: 15px;
  font-weight: 900;
}

.label-float-left {
  float: left;
}

.label-float-right {
  float: right;
}

.border-bottom {
  border-bottom: 1px solid #cccccc;
}

.layer-color {
  background-color: #f1f1f1;
}

.number-text {
  border: 1px solid #cccccc;
  border-radius: 50%;
  padding: 3px 8px;
  font-size: 12px;
  background-color: #f1f1f1;
}

.number-text-big {
  border: 1px solid #cccccc;
  border-radius: 50%;
  padding: 8px 14px;
  font-size: 15px;
  background-color: #f1f1f1;
  cursor: pointer;
}

.number-text-color {
  border: 1px solid #2B96C0;
  border-radius: 50%;
  padding: 8px 14px;
  font-size: 15px;
  background-color: #CFF1FF;
  cursor: pointer;
}

.number-padding-left {
  padding-left: 10px;
}

.number-bold {
  font-weight: 600;
}

.number-padding {
  padding: 20px 20px 20px 0px;
}

.text-header {
  border: 1px solid #f1f1f1;
  background-color: #f5f5f5;
  padding: 20px;
  display: flex;

  .text-header-left {
    font-size: 16px;
    font-weight: 800;
    text-align: left;
    width: 34%;
  }

  .text-header-right {
    float: right;
    width: 66%;
  }
}

/* merge lists */
.storage-list-item {
  display: inline-flex;
  flex-basis: 100%;
  max-width: 100%;

  &:hover {
    background: $task-hover;

    .checkbox-holder span {
      display: block;
    }
  }

  &.show-checkbox {
    .checkbox-holder span {
      display: block;
    }
  }

  .checkbox-holder {
    position: relative;
    width: 65px;

    span {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      @include translateXY(-50%, -50%);
    }

    @include break($tablet-screen) {
      width: 35px;

      .edit-bale-container {
        flex-flow: column wrap;
        display: block;

        .field-area {
          width: 100%;
          min-width: 300px;
          padding: 30px;

          .field-map {
            height: 300px;
          }
        }

        .bale-settings {
          width: 100%;
          padding-bottom: 20px;

          .form-group {
            .form-control {
              width: 100%;
            }
          }
        }
      }

      span {
        display: block;
      }
    }
  }

  .storage-list-item-link {
    background: $task-bg;
    border-top: 1px solid $task-list-bdr;
    border-right: 1px solid $task-list-bdr;
    color: $general-text-color;
    float: left;
    padding: 12px 10px 12px 15px;
    width: calc(100% - 65px);
    cursor: pointer;

    &.grey-bdr {
      border-left: 4px solid $task-list-bdr;
    }

    &.green-bdr {
      border-left: 4px solid $task-green-indicator;
    }

    @include break($tablet-screen) {
      width: calc(100% - 35px);
    }
  }

  .storage-list-item-selected-link {
    background: $task-bg;
    border: 1px solid $task-list-bdr;
    color: $general-text-color;
    padding: 10px 8px 10px 8px;
    width: calc(100%);
    display: inline-flex;

    @include break($tablet-screen) {
      width: calc(100%);
    }
  }

  .storage-list-item-div {
    background: $task-bg;
    border-top: 1px solid $task-list-bdr;
    border-right: 1px solid $task-list-bdr;
    color: $general-text-color;
    float: left;
    padding: 12px 12px 12px 12px;
    width: calc(100% - 65px);
    font-size: 13px;

    &.grey-bdr {
      border-left: 4px solid $task-list-bdr;
    }

    &.green-bdr {
      border-left: 4px solid $task-green-indicator;
    }

    @include break($tablet-screen) {
      width: calc(100% - 35px);
    }
  }

  .item-lbl {
    float: left;
    font-size: 12px;
    font-weight: $font-light;
  }

  .item-value {
    clear: both;
    float: left;
    font-weight: $font-semi-bold;
    margin-top: 18px;
  }
}

.medium-font-text {
  font-size: 15px;
}

.vertical-align-top {
  vertical-align: top;
}

.border-bottom-style {
  border-bottom: 1px solid #cccccc;
}

.medium-width {
  width: 60%;
}

.icon-width {
  min-width: 5%;
}

.minimum-width {
  width: 30%
}

.large-width {
  width: 72%;
}

.scheduler-large-width {
  width: 82%;
}

.scheduler-extra-large-width {
  width: 92%;
}

.modal-large-width {
  width: 90%;
}

.max-width {
  width: 250px;
}

.add-button-list-item {
  border-left: 4px solid #aeaeae;
  padding: 15px 0px;
  border-bottom: 1px solid #cccccc;
  width: 100%;
  border-right: 1px solid #cccccc;
}

.breadCrumb-text {
  padding-left: 20px;
}

.breadCrumb-span {
  padding-right: 20px;
  border-right: 2px solid #cccccc;
}

.breadCrumb-span a {
  color: #aeaeae;
  font-size: 12px;
}

.breadCrumb-span-modal {
  color: #666;
  font-size: 14px;
  font-weight: 600;
  float: left;
  padding-top: 5px;
  border-right: 2px solid #ccc;
  padding-right: 10px;
}

.breadCrumb-scheduler-span-modal {
  color: #666;
  font-size: 12px;
  font-weight: 600;
  float: left;
  padding-top: 7px;
  padding-right: 4px;
  cursor: pointer;
}

.padding-bottom-div {
  padding-bottom: 20px;
}

.padding-top-div {
  padding-top: 20px;
}

.padding-top-div-small {
  padding-top: 9px;
}

.padding-top-div-extra-small {
  padding-top: 5px;
}

.padding-top-div-extra-large {
  padding-top: 40px;
}

.padding-top-div-medium {
  padding-top: 12px;
}

.padding-top-div-large {
  padding-top: 24px;
}

.font-style-div {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.font-style-div1 {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  padding: 10 0;
}

.location-type-label {
  float: left;
  padding-right: 20px;
  padding-top: 3px;
}

.location-type-checkbox {
  float: left;

  .hOBkiN {
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    padding-left: 32px;
  }
}

.location-type-checkbox-right {
  float: right;
}

.location-type-checkbox-width {
  width: 12%
}

.storage-class-left {
  float: left;
  width: 100%
}

.fieldNote {
  .form-group label {
    font-size: 13px;
    font-weight: 600;
  }
}

.bale-error {
  float: right;
  padding-bottom: 15px;
}

.bale-edit-link {
  color: #2B96C0;
  font-size: 13px;
  cursor: pointer;
}

.no-padding-top {
  padding-top: 0px !important;
}

.edit-bale-container {
  display: flex;
  background: $edit-fields-bg;
  justify-content: space-between;
  flex-flow: row nowrap;
  padding-top: 15px;

  .field-area {
    flex: 0 0 59%;
    width: 59%;
    min-width: 300px;
    box-sizing: border-box;
    background-color: $page-bg-color;
    padding: 30px 25px;

    .field-map {
      height: 395px;
      @include bdr-radius(5px);
      position: relative;
    }
  }

  .close-modal {
    margin: 0 15px;
    float: right;
  }
}

.bale-settings {
  flex: 0 0 40%;
  width: 40%;
  min-width: 300px;
  box-sizing: border-box;
  background-color: $page-bg-color;
  position: relative;
  display: flex;
  flex-direction: column;

  .delete-field-button {
    padding: 15px 40px 0px 40px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    .delete-icon {
      display: inline-block;
      vertical-align: middle;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .disable-delete {
      fill: $disable-color;
    }
  }

  .pad-right {
    padding-right: 1px;
  }
}

.bale-settings-form {
  padding: 25px 40px 0px 40px;

  .form-group {
    .textarea-placeholder-size {
      height: calc(100% - 20px);
      width: calc(100% - 25px) !important;
      color: #000;
      font-size: 14px;
      font-weight: 300;
      line-height: 17px;
      margin: 10px 12px;
      border: none;
      padding: 0px;
    }

    .input-note {
      height: 160px;
      min-width: 250px;
      border: 1px solid $input-bdr-color;
      @include bdr-radius(4px);
      margin-top: 5px;
    }

    .error-message {
      left: 0;
      width: auto;
    }
  }

  .sizeField {
    .form-group {
      max-width: 166px;
    }

    &+div label {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .fieldNote {
    .form-group {
      padding-bottom: 0;
    }
  }

  .updated-div {
    margin-top: 10px;
    float: left;

    span {
      padding: 5px 0 0 10px;
      float: left;
    }
  }
}

.styled-components__StyledContent-sc-9itodg-4 {
  margin: 2rem 2rem 0 !important;
}

.bale-option {
  width: 100%;
  padding-top: 90px;

  .bale-option__yes-no {
    float: left;
    margin: 18px 18px 0 -15px;
  }
}

.bale-storage-form-style {
  display: block;
  margin-top: 20px;
  padding-left: 2%;
  padding-right: 2%;

  .styled-components__StyledUnitWrapper-cmng82-5 {
    min-width: 38%;
  }

  .iSxzBa {
    width: 0 !important;
    min-width: 0 !important;
  }

  .iSxzBa svg {
    height: 0 !important;
  }
}

.main-bale-storage-form-style {
  display: block;
  margin-top: 20px;
  padding-left: 1%;
  padding-right: 1%;

  .styled-components__StyledUnitWrapper-cmng82-5 {
    min-width: 38%;
  }

  .iSxzBa {
    width: 0 !important;
    min-width: 0 !important;
  }

  .iSxzBa svg {
    height: 0 !important;
  }
}

.pointer-cursor {
  cursor: pointer;
}

.modal-bale-storage-form-style {
  display: block;
  margin-top: 20px;

  .styled-components__StyledUnitWrapper-cmng82-5 {
    min-width: 22%;
  }

  .iSxzBa {
    width: 0 !important;
    min-width: 0 !important;
  }

  .iSxzBa svg {
    height: 0 !important;
  }
}

.kaQexp {
  font-size: 0.9rem !important;
}

.bale-icon-size {
  height: 1.5rem;
  width: 1.9rem;
}

.bale-icon-size-link {
  height: 2.4rem;
  width: 2.8rem;
  float: right;
}

.bale-icon-size-small {
  height: 1.2rem;
  width: 1.6rem;
}

.bale-blue-icon-size {
  height: 1.6rem;
  width: 1.6rem;
}

.bale-storage-bale {
  float: right;
  padding-right: 6px;
  margin-top: 2px;
}

.harvest-number-column {
  float: right !important;
  width: 30% !important;
}

.scheduler-harvest-number-column {
  float: right !important;
  width: 12% !important;
}

.icon-width {
  img {
    vertical-align: middle !important;
  }
}

.vertical-align-sub {
  vertical-align: sub;
}

.vertical-align-super {
  vertical-align: super;
}

.storage-arrow {
  span {
    vertical-align: inherit !important;
  }

  div {
    vertical-align: inherit !important;
  }

  img {
    vertical-align: inherit !important;
  }
}

.style-cursor {
  cursor: pointer;
}

.bale-button-style {
  padding-top: 20px;
  text-align: center;
  padding-bottom: 20px;
}

.bale-button-style-middle {
  padding-top: 20px;
  display: inline-block;
  padding-left: 88px;
  padding-bottom: 20px;
}

.bale-list-cancel-button {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
  border: thin solid #2a3c63 !important;
  border-radius: 1.5rem;
  color: #2a3c63 !important;
  padding: 0.55rem 2rem;
}

.bale-list-save-button {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
  border: thin solid #2a3c63 !important;
  border-radius: 1.5rem;
  color: #ffffff !important;
  background-color: #2a3c63 !important;
  padding: 0.55rem 2rem;
}

.bale-area {
  width: 100%;
  min-width: 250px;
  box-sizing: border-box;
  background-color: $page-bg-color;

  .field-map {
    height: 220px;
    @include bdr-radius(5px);
    position: relative;
  }
}

.lightbox-field-padding-top {
  padding-top: 15px;
  display: inline-block;
  width: 100%;
}

.lightbox-field-padding-top-large {
  padding-top: 25px;
  display: inline-block;
  width: 100%;
}

.lightbox-padding-bottom-div {
  padding-bottom: 15px;
}

.lightbox-padding-bottom-div-small {
  padding-bottom: 8px;
}

.font-small-size {
  font-size: 12px;
}

.padding-row-small {
  padding-right: 5px;
}

.remove-cursor {
  cursor: default !important;
}

.margin-top-add-storages {
  margin-top: -5px !important;
}

.align-justify-span {
  text-align: justify;
}

.smallWidth {
  width: 5%;
}

.mediumWidth {
  width: 15%;
}

.largeWidth {
  width: 80%;
}

.schedulerMediumWidth {
  width: 17%;
}

.schedulerWidth {
  width: 76%;
}

.small-font-span {
  font-size: 0.8rem;
  padding-right: 3px;
}

.medium-font-span {
  font-size: 0.9rem;
  padding-right: 3px;
}

.medium-scheduler-font-span {
  font-size: 0.8rem;
  padding-right: 3px;
}

.small-font-span-bold {
  font-size: 0.8rem;
  font-weight: 600;
  padding-right: 10px;
}

.small-font-span-bold-padding {
  font-size: 0.8rem;
  font-weight: 600;
}

.medium-font-span-bold {
  font-size: 0.9rem;
  font-weight: 600;
  padding-right: 5px;
}

.set-background-color {
  background-color: #e8f4f9 !important;
}

.analysis-column {
  float: left;
  width: 100%;

  .left-label {
    font-size: 0.9rem;
    float: left;
    width: 40%;
    padding: 10px 0px;
  }

  .right-label {
    font-size: 0.9rem;
    float: left;
    width: 60%;
    padding: 10px 0px;
  }
}

.display-inline-block {
  display: inline-block;
  width: 100%;
}

.display-fields-array {
  padding: 10px 0px;
  border-bottom: 1px solid #e1e1e1;
}

.storage-trash-icon {
  background-color: #fff;
  text-align: right;
  padding-top: 30px;
}

.scheduler-title {
  padding: 15px;
  color: #000;
}

.text-align-end {
  text-align: end;
}

.scheduler-box-display {
  padding-left: 24px;
  margin-top: 5px;
  display: block;
}

.circle-icon {
  background: #038cfc;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
}

.circle-icon-disable {
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
}

.align-center-div {
  padding-top: 10px;
  text-align: -webkit-center;
}

.low-padding-top {
  padding-top: 2px;
}

.scheduler-list-border {
  border: 1px solid #ccc;
  padding: 10px 5px;
}

.blue-color-link {
  color: #038cfc;
  cursor: pointer;
}

.custom-checkbox {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.storage-heading-modal {
  float: left;
  padding-left: 25px;
  font-weight: 600;
  font-size: 20px;
}

.storage-column-padding {
  padding-bottom: 26px !important;
}

.schedulerContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.schedulerContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
}

/* On mouse-over, add a grey background color */
.schedulerContainer:hover input~.checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a green background */
.schedulerContainer input:checked~.checkmark {
  background-color: #06842e;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.schedulerContainer input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.schedulerContainer .checkmark:after {
  left: 8px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2.5px 2.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.no-border-agreement {
  border: none !important;
}

.agreement-float {
  float: right;
  padding-right: 20px;
}

.agreement-padding-top {
  padding-top: 5px;
}

.agreement-padding-left {
  padding-left: 8px;
}

.agreement-float-left {
  float: left;
}

.agreement-margin-right {
  margin-right: 20px;
}

.agreement-padding-right {
  padding-right: 15px;
}

.agreement-header {
  padding: 10px 0;
  text-align: center;
  font-weight: bold;
}

.paddingLeftFields {
  padding-left: 20px;
}

.paddingLeftFieldsRight {
  float: right;
}

.paddingLeftFieldsRightPadding {
  padding: 0.55rem 1.4rem !important;
}

.location-type-checkbox {
  label {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}