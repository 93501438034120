.manage-users-wrapper {
    position: relative;

    .pointer-events-none {
        pointer-events: none;
    }

    .cursor-not-allowed {
        cursor: not-allowed;
    }

    .add-user {
        float: right;
        position: absolute;
        right: 40px;
        top: 30px;

        .pad-right {
            padding-right: 5px;
        }

        .add-icon {
            display: inline-block;
            vertical-align: middle;
            color: $card-new-icon;

            svg {
                width: 23px;
                height: 23px;
            }
        }

        @include break($laptop-screen) {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            float: none;
            right: 0;
            top: 0;
            margin: 10px 20px 0px;
        }
    }

    .manage-users {
        max-width: 75%;
        position: relative;

        .user-grid-wrapper {

            [class^="styled-components__FlexGridWrapper"],
            [class*=" styled-components__FlexGridWrapper"] {
                overflow: visible;
            }

            [class^="styled-components__FlexGridSection"],
            [class*=" styled-components__FlexGridSection"] {
                grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
            }
        }

        .user-card {
            position: relative;

            .user-loader {
                position: absolute;
                left: 50%;
                top: 50%;
                @include translateXY(-50%, -50%);
            }

            .user-card-detail {
                border: 1px solid $user-card-border;
                border-radius: 2px;
                border-left: 4px solid $user-card-border;

                .user-card-detail-wrapper {
                    padding: 34px 29px 26px 24px;

                    .user-card-heading {
                        display: flex;
                        justify-content: space-between;

                        div:first-child {
                            display: flex;
                        }

                        .user-card-name {
                            align-self: center;
                            flex: 1;
                            overflow: hidden;
                        }

                        img {
                            height: 43px;
                            width: 43px;
                            border-radius: 100%;
                        }

                        p {
                            align-self: center;
                            font-size: 20px;
                            font-weight: 600;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    .user-card-delete {
                        float: right;
                        align-self: center;

                        .delete-icon {
                            border-radius: 50%;
                            background-color: $user-trash-bg;
                            color: $card-plus-icon;
                            font-size: 23px;
                            vertical-align: middle;
                            padding: 10px;
                            font-weight: 300;
                            width: 43px;
                            height: 43px;
                            cursor: pointer;

                            svg {
                                transform: translate(5%, 0%);
                                fill: $page-bg-color;
                            }

                            &.disabled {
                                background: $disable-color;
                            }
                        }
                    }

                    .user-card-info {
                        margin: 15px 0px 20px;

                        p {
                            margin: 5px 0px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;

                            span:nth-child(2) {
                                font-weight: 600;
                            }
                        }

                        .user-card-email a {
                            color: $general-text-color
                        }
                    }

                    .align-user-val {
                        padding-left: 5px;
                    }
                }
            }

            .user-card-detail:hover {
                border-left: 4px solid $user-card-hover;
            }
        }

        @include break($laptop-screen) {
            max-width: 100%;
        }

        @include break($mobile-screen) {
            max-width: 100%;
        }
    }

    .no-users {
        padding-top: 50px;
        padding-left: 30px;
    }

    .manage-users-loader {
        position: absolute;
        margin: 0 auto;
        top: 50%;
        left: 50%;
        @include translateXY(-50%, -50%);

        @include break($laptop-screen) {
            top: 400px;
            left: 400px;
        }

        @include break($mobile-screen) {
            left: 200px;
        }
    }

    a {
        color: $general-text-color;
    }
}

/*
* Add new users
*/
.add-user-wrapper {
    padding: 37px 45px;
    max-width: 343px;
    box-sizing: content-box;
    position: relative;

    .add-user-footer {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding: 45px 0px 0px;
    }

    .add-user-loader {
        position: absolute;
        right: 65px;
        margin-top: 5px;
    }

    @include break($mobile-screen) {
        padding: 15px 10px;
    }
}