.add-farm-map-wrapper {
    justify-content: space-between;
    background: $edit-fields-bg;

    .add-farm-form {
        width: 32.5%;
        padding: 42px 42px 0 42px;
        box-sizing: content-box;
        background-color: $page-bg-color;
        position: relative;

        .form-group {
            .form-control {
                width: 408px;
                height: 43px;
                text-align: left;
            }

            &:nth-last-of-type(2) {
                padding-bottom: 0px;
            }
        }

        .add-farm-type {
            display: inline-flex;
            width: 100%;
            justify-content: space-around;

            .farm-type {
                clear: both;
                float: left;
                margin-top: 4px;
                margin-bottom: 21px;
                width: auto;
            }
        }

        .add-farm-required {
            clear: both;
            left: 0px;
            text-align: right;
            padding-top: 5px;
            font-size: 12px;
        }

        .add-farm-footer {
            text-align: right;
            margin: 20px 0;
        }

        .form-error {
            color: $failure-color;
            margin-bottom: 10px;
        }

        .validation-map {
            text-align: right;
        }

        .farm-loader {
            position: fixed;
            left: 50%;
            top: 50%;
            z-index: 100;
            transform: translate(-50%, -50%);
        }

        .updated-div {
            span {
                padding: 5px 0 0 10px;
                float: left;
            }
        }
    }

    .farm-header-holder {
        background-color: $page-bg-color;
        border-bottom: 10px solid $edit-fields-bg;

        .add-farm-icons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin: 15px 0;

            .add-farm-button {
                padding: 0 10px;
                cursor: pointer;

                .delete-icon,
                .add-icon {
                    display: inline-block;
                    vertical-align: middle;
                }

                .add-icon {
                    color: $card-new-icon;
                    padding-right: 20px;

                    svg {
                        width: 23px;
                        height: 23px;
                    }
                }

                .delete-icon {
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }

                .disable-delete {
                    fill: $disable-color;
                }

                a {
                    color: $general-text-color;
                }
            }

            .pad-right {
                padding-right: 5px;
            }
        }
    }

    .add-farm-details-map-holder {
        display: flex;
    }

    .add-farm-map {
        width: calc(100% - (32% + 10px));
        position: relative;
        background-color: $page-bg-color;

        .add-farm-map-holder {
            padding: 75px 25px 15px;
            position: relative;

            @include break($laptop-screen) {
                // padding: 5px;
            }

            .hide {
                display: none;
            }
        }

        .map-note {
            padding-top: 7px;
            font-size: 12px;
        }

        .add-farm-map-footer {
            text-align: center;
            margin-top: 15px;
        }

        .farm-map-loader {
            position: absolute;
            top: 30%;
            left: 45%;
        }
    }
}

.add-farm-container {
    @extend .full-width;
    text-align: center;
    margin: 156px 0;

    .add-farm-wrapper {
        width: 239px;
        margin: 0 auto;

        .add-form-icon {
            height: 50.4px;
            font-size: 58px;
            width: 58px;
        }

        .add-no-farm {
            margin-top: 10px;
            font-size: 20px;
            font-weight: 600;
        }

        .add-farm-message {
            margin-bottom: 15px;
        }

        .add-button {
            margin-top: 20px;
        }
    }
}

@include break($laptop-screen) {
    .add-farm-map-wrapper {
        .add-farm-form {
            width: 40%;
            padding: 20px 20px 0;
        }

        .add-farm-map .add-farm-map-holder {
            padding: 20px;
        }
    }


}

@include break($tablet-screen) {
    .add-farm-map-wrapper {
        display: flex;
        flex-direction: column;

        .add-farm-details-map-holder {
            flex-direction: column-reverse;
        }

        .add-farm-form {
            width: 100%;
            box-sizing: border-box;
            border-right: none;
            border-top: 10px solid $edit-fields-bg;

            .form-group {
                .form-control {
                    width: 100%;
                }
            }

            .add-farm-type {
                width: 100%;
            }
        }

        .add-farm-map {
            width: 100%;
            padding: 15px;

            .add-farm-map-holder {
                padding: 10px 25px 15px;
            }
        }

    }
}

@include break($mobile-screen) {
    width: 100%;

    .add-farm-container {
        margin-top: 90px;
    }

    .add-farm-map-wrapper {
        display: flex;
        flex-direction: column;

        .add-farm-form {
            width: 100%;
            padding: 25px 10px 0 10px;
            box-sizing: border-box;

            .form-group {
                .form-control {
                    width: 100%;
                }
            }

            .add-farm-type {
                width: 100%;
            }
        }

        .add-farm-map {
            width: 100%;
            padding: 10px;
        }
    }

    .ruler {
        margin: 50px 50px 130px 50px;
    }
}

.add-farm-map-holder {

    &.hide {
        .leaflet-draw.leaflet-control {
            display: none;
        }
    }

    &.gpsHide {
        .leaflet-pane .leaflet-marker-pane {
            img[src="images/userLocation.svg"] {
                visibility: hidden;
            }
        }
    }

    &.gpsShow {
        .leaflet-pane .leaflet-marker-pane {
            img[src="images/userLocation.svg"] {
                visibility: visible;
            }
        }
    }
}

.gps {
    position: absolute;
    bottom: 95px;
    right: 35px;
    background-color: $map-gps-bg-color;
    border-radius: 50%;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    background-size: 32px;
    color: $map-icon-bg-color;
    font-size: 32px;
    background-clip: padding-box;
    z-index: $gps-zindex;

    &:hover {
        background-color: $map-gps-bg-color-hover;
    }

    &:active {
        background-color: $map-gps-bg-color-active;
    }
}

.farm-map-container {
    height: 445px;
    width: 100%;
}

.manageFarmsLayout {
    display: flex !important;
    padding: 5px 128px !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex: 1 0 0 !important;
    align-self: stretch !important;
    width: 100% !important;

    @include break($mobile-screen) {
        padding: 0px !important;
    }
}

.manageFarmsLayoutDivLayout {
    display: flex;
    padding: 0px 128px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    width: 100%;

    @include break($mobile-screen) {
        padding: 0px;
    }
}

.manageFarmsLayoutDivLayoutMobile {
    display: flex;
    padding: 0px 0px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    width: 100%;
}

.manageFarmsLayoutDiv {
    display: flex;
    width: 340px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex: 1 0 0;
    align-self: stretch;
}

.manageFarmsLayoutDivMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex: 1 0 0;
    align-self: stretch;
}

.manageFarmsLayoutDiv1 {
    display: flex;
    width: 340px;
    flex-direction: column;
    align-items: center;
    gap: 48px;
}
.manageFarmsLayoutDiv2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    align-self: stretch;
}

.manageFarmsLayoutDialogDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    flex: 1 0 0;
    align-self: stretch;
}

.updated-div-mui {
    font-family: 'Inter';
    font-size: 14px;
    color: '#00696D';
}