@import 'variables';
@import 'helpers';

.padding-top-bottom {
  .form-group {
    padding-bottom: 15px !important;
  }
}

.padding-top-css {
  .form-group {
    padding-top: 15px !important;
  }
}

.padding-top-css-text {
  padding-top: 15px !important;
}

.padding-bottom-css-text {
  padding-bottom: 10px !important;
}

.react-datepicker-wrapper {
  .react-datepicker__input-container {
    input {
      height: 50px;
      font-size: 16px;
      font-family: Open Sans, sans-serif;
      padding: 10px;
      width: 100%;
      border: thin solid #babcbc;
      border-radius: 5px;
    }
  }
}

.grass-list-container>div {
  grid-template-columns: minmax(35rem, 1fr) 0 0;
  -ms-grid-columns: minmax(35rem, 1fr) 0 0;

  @include break($mobile-screen) {
    grid-template-columns: minmax(30rem, 1fr) 0 0;
    -ms-grid-columns: minmax(35rem, 1fr) 0 0;
  }
}

/*.react-datepicker__month-dropdown-container--scroll {
  float: left;
  padding-left: 42px;
}*/

.grass-list-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 15px 20px 15px;

  .list-item {
    .grass-details {
      display: inline-flex;
      flex-basis: 100%;
      width: 100%;

      .grass-crop {
        border-right: 1px solid $common-bdr-color;
        padding: 0 15px;
        max-width: 80px;
        min-width: 66px;
      }

      .grass-crop-no-grass {
        border-right: 1px solid $common-bdr-color;
        padding: 0 15px;
        max-width: 105px;
        min-width: 105px;
      }

      .grass-crop-no-grass-feedmix {
        border-right: 1px solid $common-bdr-color;
        padding: 0 15px;
        max-width: 80px;
        min-width: 80px;
      }

      .grass-crop-no-grass-small {
        border-right: 1px solid $common-bdr-color;
        padding: 0 15px;
        max-width: 125px;
        min-width: 125px;
      }

      .grass-crop-with-grass {
        border-right: 1px solid $common-bdr-color;
        padding: 0 15px;
        max-width: 100px;
        min-width: 100px;
      }

      .field-small-width {
        border-right: 1px solid $common-bdr-color;
        padding: 0 15px;
        max-width: 10%;
        min-width: 10%;
      }

      .field-medium-width {
        border-right: 1px solid $common-bdr-color;
        padding: 0 15px;
        max-width: 12%;
        min-width: 12%;
      }

      .field-big-width {
        border-right: 1px solid $common-bdr-color;
        padding: 0 15px;
        max-width: 17%;
        min-width: 17%;
      }

      .field-big-width-last {
        padding: 0 15px;
        max-width: 17%;
        min-width: 17%;
      }

      .grass-crop-details-with-grass {
        border-right: 1px solid $common-bdr-color;
        padding: 0 15px;
        max-width: 16%;
        min-width: 16%;
      }

      .grass-crop-details-with-grass-last {
        padding: 0 15px;
        max-width: 14%;
        min-width: 14%;
      }

      .grass-crop-unit {
        border-right: 1px solid $common-bdr-color;
        padding: 0 5px;
        max-width: 80px;
        min-width: 85px;
      }

      .grass-crop-sugar {
        border-right: 1px solid $common-bdr-color;
        padding: 0 5px;
        max-width: 80px;
        min-width: 45px;
      }

      .grass-crop-omd {
        border-right: 1px solid $common-bdr-color;
        padding: 0 5px;
        max-width: 80px;
        min-width: 45px;
      }

      .grass-crop1 {
        border-right: 1px solid $common-bdr-color;
        padding: 0 5px;
        max-width: 66px;
        min-width: 66px;
      }

      .grass-crop-field {
        border-right: 1px solid $common-bdr-color;
        padding: 0 15px;
        max-width: 15%;
        min-width: 15%;

        /*.item-value {
          margin-top: 8px !important;
        }*/
      }

      .grass-crop-field-no-grass {
        border-right: 1px solid $common-bdr-color;
        padding: 0 15px;
        max-width: 16%;
        min-width: 16%;
      }

      .grass-crop-field-with-grass {
        border-right: 1px solid $common-bdr-color;
        padding: 0 15px;
        max-width: 20%;
        min-width: 20%;
      }

      .grass-crop-field1-with-grass {
        border-right: 1px solid $common-bdr-color;
        padding: 0 15px;
        max-width: 20%;
        min-width: 20%;

        /*.item-value {
          margin-top: 8px !important;
        }*/
      }

      .grass-crop-date {
        border-right: 1px solid $common-bdr-color;
        padding: 0 5px;
        max-width: 7%;
        min-width: 7%;
      }

      .grass-crop-date-no-grass {
        border-right: 1px solid $common-bdr-color;
        padding: 0 15px;
        max-width: 10%;
        min-width: 10%;
      }

      .grass-crop-date-with-grass {
        border-right: 1px solid $common-bdr-color;
        padding: 0 15px;
        max-width: 15%;
        min-width: 15%;
      }

      .grass-crop-date-with-grass-feedmix {
        border-right: 1px solid $common-bdr-color;
        padding: 0 15px;
        max-width: 12%;
        min-width: 12%;
      }

      .grass-analysis-type {
        border-right: 1px solid $common-bdr-color;
        max-width: 11%;
        min-width: 11%;
      }

      .grass-analysis-type-no-grass {
        border-right: 1px solid $common-bdr-color;
        padding: 0 15px;
        max-width: 14%;
        min-width: 14%;
      }

      .grass-analysis-type-with-grass {
        padding: 0 15px;
        border-right: 1px solid $common-bdr-color;
        max-width: 18%;
        min-width: 18%;
      }

      .grass-crop-last-with-grass {
        padding: 0 15px;
        max-width: 100px;
        min-width: 100px;
      }

      .grass-crop-last-no-grass {
        padding: 0 15px;
        max-width: 105px;
        min-width: 105px;
      }

      .grass-crop-last-no-grass-feedmix {
        padding: 0 15px;
        max-width: 105px;
        min-width: 105px;
      }

      .grass-crop-last {
        padding: 0 5px;
        max-width: 5%;
        min-width: 5%;
      }

      .grass-crop-delete {
        padding: 18px 0px 0px 18px;
        max-width: 2%;
        min-width: 2%;
      }

      .grass-crop-drymatter {
        border-right: 1px solid $common-bdr-color;
        padding: 0 15px;
        max-width: 2%;
        min-width: 2%;
      }

      .grass-crop {
        width: 10%;
        max-width: 10%;

        .item-value {
          max-width: 90px;
        }
      }

      .grass-crop-unit {
        width: 5%;
        max-width: 5%;

        .item-value {
          max-width: 90px;
        }
      }

      .grass-crop1 {
        width: 5%;
        max-width: 5%;

        .item-value {
          max-width: 80px;
        }
      }

      .field-size {
        border-right: 0;
      }

      .grass-titlecard {
        min-width: 16%;
        padding: 0 5px;
        border-right: 1px solid #eee;

        .item-value {
          margin-top: 16px !important;
        }
      }

      .grass-titlecard-no-grass {
        min-width: 16%;
        padding: 0 15px;
        border-right: 1px solid #eee;

        .item-value {
          margin-top: 16px !important;
        }
      }

      .grass-titlecard-with-grass {
        min-width: 22%;
        padding: 0 15px;
        border-right: 1px solid #eee;

        .item-value {
          margin-top: 16px !important;
        }
      }

      .grass-messagecard {
        padding: 25px 0px 15px 15px;
        word-wrap: break-word;
      }

      .grass-messagecard-no-top {
        width: 34%;
        padding: 0px 0px 15px 15px;
      }

      .field-image {
        min-width: 28%;
        display: flex;
        align-items: center;
        padding-left: 0;

        .grass-tile {
          float: right;
          width: calc(100% - 85px);
        }

        .field-img {
          float: left;
          height: 65px;
          width: 65px;
        }

        .field-name {
          float: right;
          font-weight: $font-light;
          margin: 23px 15px 0 0;
          text-align: right;
          height: 20px;
          width: calc(100% - 85px);
        }
      }

      @include break($mobile-screen) {
        display: contents;
        position: relative;

        .field-image {
          min-width: 50%;
        }

        .grass-crop,
        .field-size {
          max-width: 15%;

          .item-value {
            width: 100%;
          }
        }

        .grass-titlecard {
          padding: 0 15px;
          max-width: 17%;

          .item-value {
            margin-top: 16px !important;
          }
        }

        .grass-titlecard-no-grass {
          padding: 0 15px;
          max-width: 18%;

          .item-value {
            margin-top: 16px !important;
          }
        }

        .grass-titlecard-with-grass {
          padding: 0 15px;
          max-width: 22%;

          .item-value {
            margin-top: 16px !important;
          }
        }

        .grass-messagecard {
          width: 75%;
          padding-left: 15px;
        }

        .field-tempsum {
          max-width: 20%;

          .item-value {
            width: 100%;
          }
        }
      }
    }
  }
}

.select-box-grass {
  float: right;
  margin: 5px 0 7px 0px;
  width: 100%;
  min-height: 50px;
}

.action-menu__items_right {
  float: right;
}

.grass-list-item-link {
  background: $task-bg;
  border: 1px solid $task-list-bdr;
  border-left: 4px solid $task-grey-indicator;
  color: $general-text-color;
  float: left;
  padding: 10px 10px 10px 10px;
  width: calc(100% - 5px);
  cursor: pointer;

  &.grey-bdr {
    border-left: 4px solid $task-grey-indicator;
  }

  &.green-bdr {
    border-left: 4px solid $task-green-indicator;
  }

  @include break($tablet-screen) {
    width: calc(100% - 35px);
  }
}

#selectGrassFields {
  width: 220px;
  height: 32px;
  border: 1px solid #c1c1c1;
}

.feed-mix-flex {
  display: inline-flex;
  flex-wrap: wrap;

  .select-box-common {
    width: 165px !important;
    padding-right: 10px;
  }

  .select-box-common1 {
    width: 170px !important;
    padding-right: 10px;
  }

  .select-box-common2 {
    width: 120px !important;
    padding-right: 10px;
  }

  .select-box-amount {
    width: 135px !important;
    padding-right: 20px;
    padding-top: 20px;

    div div div {
      padding-top: 3px;
    }
  }
}

.multiple-feedmix-section {
  .multiple-feedmix-sub-section {
    .form-group {
      @include break($laptop-screen) {
        max-width: 270px;
        width: 270px;
      }

      @include break($tablet-screen) {
        max-width: 200px;
        width: 200px;
      }

      @include break($mobile-screen) {
        max-width: 140px;
        min-width: 140px;
      }
    }

    .add-icon {
      color: $card-new-icon;
    }

    .delete-icon {
      @include bdr-radius(50%);
      background-color: $user-trash-bg;
      color: $card-plus-icon;
      font-size: 23px;
      vertical-align: middle;
      padding: 10px;
      font-weight: 300;
      width: 35px;
      height: 35px;
      margin-right: 10px;

      svg {
        transform: translate(-18%, -35%);
        fill: $page-bg-color;
      }
    }

    .pesticide-section-controls {
      display: flex;
      margin-top: 55px;

      div {
        cursor: pointer;
      }

      @include break($laptop-screen) {
        width: 100px;
        max-width: 140px;
      }

      @include break($tablet-screen) {
        width: 50px;
        max-width: 70px;
      }
    }
  }
}

.grass-details-border-bottom {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 15px;
}

.grass-details-border-top {
  padding-top: 15px;
  padding-bottom: 15px;
}

.grass-details-border {
  border-top: 1px solid #eeeeee;
  padding-left: 15px;
}

.spanGrassLink {
  cursor: pointer;
}

.fontWeightBold {
  font-weight: 700;
}