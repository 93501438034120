.profile-settings {
    float: left;
    max-width: 690px;
    position: relative;
    padding: 40px 0 40px;
    width: 100%;

    @include break($laptop-screen) {
        min-height: 700px;
    }

    .update-pwd-link {
        float: right;
        font-weight: $font-light;
        font-size: 12px;
    }

    .sent-notify {
        margin: 20px 0;
        float: right;

        span {
            padding: 5px 0 0 10px;
            float: left;
        }
    }

    .btn-wapper {
        button {
            float: right;
            margin-right: 50%;
        }
    }

    .profile-loader {
        position: absolute;
        bottom: 90px;
        right: 17%;
    }

    .form-group,
    .form-control {
        width: 100%;
        max-width: 300px;
    }

    @include break($mobile-screen) {
        .profile-settings--left {
            max-width: 100%;
            width: 100%;
            padding: 20px 15px;

            .profile-align-field {
                margin-top: 0;
            }
        }

        .profile-settings--right {
            max-width: 100%;
            width: 100%;

            .profile-img {
                margin: 25px 0;
            }
        }

        .form-group,
        .form-control {
            max-width: 100%;
        }

        .btn-wapper button {
            margin: 30px 15px 0;
        }
    }

    p.error-text {
        float: left;
        width: 100%;
        text-align: right;
        margin: 10px 0 0;
    }
}

.profile-settings--left {
    float: left;
    padding: 20px 40px;
    max-width: 380px;
    width: 60%;

    .profile-align-field {
        margin-top: 58px;
    }
}

.profile-settings--right {
    float: left;
    max-width: 300px;
    text-align: center;
    width: 40%;

    .profile-img {
        margin: 53px 0 23px;
        position: relative;
        min-height: 113px;

        .profile-loader {
            bottom: -40%;
        }
    }
}

.update-password {
    max-width: 800px;
    float: left;
    padding: 0 0 20px;
    position: relative;
    border-right: 1px solid $common-bdr-color;

    .btn-wapper {

        a,
        button {
            float: right;
            margin: 60px 60px 0 0;
        }
    }

    .pwd-align {
        padding: 0 40px;

        .form-group {
            max-width: 300px;
        }
    }

    .change-pwd-block {
        padding: 15px 40px 5px;
    }

    .data-field {
        float: left;
        width: 300px;
    }

    .validation-text {
        float: left;
        padding: 7px 0 0 25px;
    }

    .error-text,
    .sent-notify {
        position: absolute;
        bottom: 70px;
    }

    .sent-notify {
        right: 50px;
    }

    .error-text {
        right: 90px;
    }

    .sent-notify span {
        padding: 5px 0 0 10px;
        float: left;
    }

    .profile-loader {
        position: absolute;
        bottom: 75px;
        right: 85px;
    }

    @include break($mobile-screen) {
        max-width: 100%;
        width: 100%;

        .validation-text {
            padding: 10px 0 0;
            width: 100%;

            p.top-align {
                margin: 0;
            }
        }

        .data-field {
            width: 100%;
        }

        .pwd-align .form-group {
            max-width: 100%;
        }
    }
}

.custom-select {
    .css-dvua67-singleValue {
        font-weight: $font-semi-bold;
        font-size: 12px;
        right: 10px;
    }
}

.agreement-link {
    color: #2B96C0;
    font-size: 14px;
    cursor: pointer;
}

.agreement-link-user {
    color: #1291c3;
    font-size: 16px;
    cursor: pointer;
    padding-top: 40px;
    padding-left: 4px;
}

.padding-agreement-link {
    padding-top: 20px
}