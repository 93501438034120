.notFoundWrapper {
    text-align: center;
    padding: 20px 0;

    h1 {
        font-size: 30px;
        font-weight: 600;
    }

    h4 {
        font-size: 20px;
        font-weight: 400;
    }

    .go-back-button {
        height: 40px;
        width: 135px;
        @include bdr-radius(20px);
        background-color: $btn-primary-bg;
        border: none;
        color: $btn-primary-color;
        margin-top: 10px;
    }
}