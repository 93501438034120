.edit-field-wrap {
  background: $edit-fields-bg;
}

.edit-field-container {
  /*display: flex;
  background: $edit-fields-bg;
  justify-content: space-between;
  flex-flow: row nowrap;
  padding-top: 15px;*/

  .field-area {
    /*flex: 0 0 64%;
    width: 64%;
    min-width: 300px;
    box-sizing: border-box;
    background-color: $page-bg-color;
    padding: 30px 25px;*/

    .field-map {
      height: 624px;
      @include bdr-radius(5px);
      position: relative;
    }
  }

  .close-modal {
    margin: 0 15px;
    float: right;
  }
}

.edit-field-newcontainer-mobile {
  display: flex;
  background: #fff;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-family: 'Inter';

  .field-area {
    flex: 0 0 100%;
    width: 100%;
    min-width: 300px;
    box-sizing: border-box;
    background-color: $page-bg-color;
    font-family: 'Inter';

    .field-map {
      height: 800px;
      @include bdr-radius(5px);
      position: relative;
    }
  }

  .weather-credit {
    text-align: left;
    font-family: 'Inter';
    font-size: 1.1rem;
    color: #333333;
    padding-left: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .chart-title-div-mobile {
    width: 100%;
    font-family: 'Inter';
    font-weight: 400;
    color: '#333';
    margin-bottom: 0.6rem;
    padding-right: 12px;
  }

  .chart-title {
    width: 100%;
    text-align: left;
    font-size: 0.8em;
    font-weight: 700;
    font-family: 'Inter';
    font-size: small;
  }

  .chart-title-mobile {
    width: 100%;
    text-align: left;
    font-size: 0.4em;
    font-weight: 700;
    font-family: 'Inter';
    padding-bottom: 10px;
  }

  .chart-year {
    width: 100%;
    text-align: right;
    float: right;
    font-family: 'Inter';
  }

  .chart-year-mobile {
    width: 100%;
    text-align: right;
    float: right;
    font-family: 'Inter';
    font-size: 12px;
    padding-bottom: 10px;
  }

  .chart-year-inner {
    padding-left: 1.0rem;
  }

  .circle-title {
    font-size: 1rem;
    font-weight: 500;
    padding-right: 0.2rem;
    display: inline-block;
    color: #333333;
    font-family: 'Inter';
  }

  .circle-title-mobile {
    font-size: 12px;
    font-weight: 500;
    padding-right: 10px;
    display: inline-block;
    color: #333333;
    font-family: 'Inter';
  }

  .circle-dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-top: 0.1rem;
    font-family: 'Inter';
  }

  .circle-dot-mobile {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-top: 5px;
    font-family: 'Inter';
  }

  .circle-font {
    font-size: 0.95rem;
    font-weight: 500;
    padding-left: 0.2rem;
    display: inline-block;
    color: #333333;
    font-family: 'Inter';
  }

  .circle-font-mobile {
    font-size: 12px;
    font-weight: 500;
    padding-left: 0.2rem;
    display: inline-block;
    color: #333333;
    font-family: 'Inter';
  }

  .close-modal {
    margin: 0 15px;
    float: right;
  }
}

.edit-field-newcontainer {
  display: flex;
  background: #fff;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 5px;

  .field-area {
    flex: 0 0 100%;
    width: 100%;
    min-width: 300px;
    box-sizing: border-box;
    background-color: $page-bg-color;
    padding: 5px;

    .field-map {
      //height: 624px;
      @include bdr-radius(5px);
      position: relative;
    }
  }

  .weather-credit {
    text-align: left;
    font-family: calibri;
    font-size: 1.1rem;
    color: #333333;
    padding-left: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .chart-title-div {
    width: 100%;
    font-family: 'Calibri';
    font-weight: 400;
    color: '#333';
    margin-bottom: 0.6rem;
  }

  .chart-title {
    width: 40%;
    text-align: left;
    font-size: 1.3em;
    font-weight: 700;
  }

  .chart-year {
    width: 60%;
    text-align: right;
    float: right;

  }

  .chart-year-inner {
    padding-left: 1.0rem;
  }

  .circle-title {
    font-size: 1rem;
    font-weight: 500;
    padding-right: 0.2rem;
    display: inline-block;
    color: #333333;
  }

  .circle-dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-top: 0.1rem;
  }

  .circle-font {
    font-size: 0.95rem;
    font-weight: 500;
    padding-left: 0.2rem;
    display: inline-block;
    color: #333333;
  }

  .close-modal {
    margin: 0 15px;
    float: right;
  }
}

.field-weather-settings {
  flex: 0 0 23%;
  width: 23%;
  background-color: $page-bg-color;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 15px;

  .field-weather {
    border: 1px solid #e1e1e1;
    padding: 10px;
  }

  .field-weather-checkbox {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .checkbox-label {
    float: left;
    font-size: 14px;
    font-weight: 500;
  }

  .field-weather-checkbox-content {
    clear: both;
    float: left;
    margin-bottom: 22px;
    font-family: 'Open Sans';
    font-size: 1.5rem;
    width: auto;
  }
}

.field-settings {
  flex: 0 0 35%;
  width: 35%;
  min-width: 300px;
  box-sizing: border-box;
  background-color: $page-bg-color;
  position: relative;
  display: flex;
  flex-direction: column;

  .delete-field-button {
    padding: 15px 40px 0px 40px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;

    .delete-icon {
      display: inline-block;
      vertical-align: middle;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .disable-delete {
      fill: $disable-color;
    }
  }

  .pad-right {
    padding-right: 5px;
  }
}

.field-settings-form {
  padding: 25px 40px 0px 40px;

  .form-group {
    .textarea-placeholder-size {
      height: calc(100% - 20px);
      width: calc(100% - 25px) !important;
      color: #000;
      font-size: 12px;
      font-weight: 300;
      line-height: 17px;
      margin: 10px 12px;
      border: none;
      padding: 0px;
    }

    .input-note {
      height: 300px;
      min-width: 250px;
      border: 1px solid $input-bdr-color;
      @include bdr-radius(4px);
      margin-top: 5px;
    }

    .error-message {
      left: 0;
      width: auto;
    }
  }

  .sizeField {
    .form-group {
      max-width: 166px;
    }

    &+div label {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .fieldNote {
    .form-group {
      padding-bottom: 0;
    }
  }

  .updated-div {
    margin-top: 10px;
    float: left;
    font-family: 'Inter';

    span {
      padding: 5px 0 0 10px;
      float: left;
    }
  }
}

.full-screen-map-wrapper {
  position: absolute;
  height: 80%;
  width: 90%;
  min-width: 80%;
  left: 5%;
  top: 12%;
}

@include break($laptop-screen) {
  .edit-field-container {
    .field-area {
      /*flex: 0 0 54%;
      width: 54%;*/
    }

    .field-settings {
      /*flex: 0 0 45%;
      width: 45%;*/
    }
  }

  .edit-field-newcontainer {
    .field-area {
      flex: 0 0 100%;
      width: 100%;
    }

    .chart-title {
      width: 100%;
      text-align: left;
    }

    .chart-year {
      width: 100%;
      text-align: left;

    }

    .field-weather-settings {
      flex: 0 0 37%;
      width: 37%;
    }
  }

  .manure-storage {
    .manure-option {
      width: 100%;
    }

    .manure-option-right {
      width: 100%;
    }
  }

  .field-option {
    width: 100%;
  }

  .field-option-right {
    width: 100%;
  }

  .manure-details {
    .insight-data-new {
      display: grid;
      grid-template-columns: auto auto;
    }

    .insight-data-bottom {
      display: grid;
      grid-template-columns: auto auto;
    }
  }
}

@include break($tablet-screen) {
  .edit-field-container {
    flex-flow: column wrap;
    display: block;

    .field-area {
      width: 100%;
      min-width: 300px;
      //padding: 30px;

      .field-map {
        height: 400px;
      }
    }

    .field-settings {
      width: 100%;
      padding-bottom: 20px;

      .form-group {
        .form-control {
          width: 100%;
        }
      }
    }
  }

  .edit-field-newcontainer {
    flex-flow: column wrap;
    display: block;

    .field-area {
      width: 100%;
      min-width: 300px;
      //padding: 30px;

      .field-map {
        height: 400px;
      }
    }

    .chart-title {
      width: 100%;
      text-align: left;
    }

    .chart-year {
      width: 100%;
      text-align: left;

    }

    .field-weather-settings {
      width: 100%;
      padding-bottom: 20px;
      padding-top: 20px;
      padding-left: 0px;

      .form-group {
        .form-control {
          width: 100%;
        }
      }
    }
  }

  .manure-storage {
    .manure-option {
      width: 100%;
    }

    .manure-option-right {
      width: 100%;
    }
  }

  .field-option {
    width: 100%;
  }

  .field-option-right {
    width: 100%;
  }

  .manure-details {
    .insight-data-new {
      display: grid;
      grid-template-columns: auto auto;
    }

    .insight-data-bottom {
      display: grid;
      grid-template-columns: auto auto;
    }
  }
}

@include break($mobile-screen) {
  font-family: 'Inter';
  .edit-field-container {
    flex-flow: column wrap;

    .field-area {
      flex-grow: 1;
      width: 100%;
    }

    .field-settings {
      width: 100%;
      padding-bottom: 20px;

      .field-settings-form {
        padding: 10px 20px 0px 20px;
      }
    }
  }

  .edit-field-newcontainer {
    flex-flow: column wrap;

    .field-area {
      flex-grow: 1;
      width: 100%;
    }

    .chart-title {
      width: 100%;
      text-align: left;
    }

    .chart-year {
      width: 100%;
      text-align: left;

    }

    .field-weather-settings {
      width: 100%;
      padding-bottom: 20px;
      padding-top: 20px;
      padding-left: 0px;

      .field-settings-form {
        padding: 10px 20px 0px 20px;
      }
    }
  }

  .manure-storage {
    .manure-option {
      width: 100%;
    }

    .manure-option-right {
      width: 100%;
    }
  }

  .manure-details {
    .insight-data-new {
      display: grid;
      grid-template-columns: auto;
    }

    .insight-data-bottom {
      display: grid;
      grid-template-columns: auto;
    }
  }

  .field-option {
    width: 100%;
  }

  .field-option-right {
    width: 100%;
  }
}

.leaflet-map-pane {
  cursor: pointer;
}

.fullscreen {
  position: absolute;
  bottom: 50px;
  right: 20px;
  background-color: $map-icon-bg-color;
  width: 32px;
  height: 32px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  font-size: 28px;
  z-index: $draw-boundary-zindex;
  padding: 1.5px 2px;

  &:hover {
    background-color: $map-fullscreen-bg-color;
  }
}

.field-image {
  .leaflet-bottom {
    display: none;
  }
}

.field-form-submit-wrap {
  clear: both;
  float: right;
  padding: 25px 30px;

  button {
    float: right;
    padding: 0.6rem 2.5rem !important;
  }

  .validation-map {
    margin-bottom: 10px;
  }
}

.manure-option {
  width: 20%;

  .manure-option__yes-no {
    float: left;
    margin: 18px 18px 0 -15px;
  }
}

.field-option__yes-no {
  float: left;
  //margin: 18px 18px 0 -15px;
}

.field-organic-style {
  float: left;
  padding-right: 20px;
  padding-left: 30px;
}

.field-removal-style {
  float: left;
  padding-right: 20px;
}

.field-option {
  width: 100%;
}

.field-option-right {
  width: 40%;
  padding: 30px;
  font-size: 22px;
}

.yield-sum {
  padding-right: 30px;
}

.yield-bold {
  font-weight: bold;
}

.manure-storage {
  background-color: #f6f6f6;
  padding: 15px;
  display: flex;
}

.field-yield-calculation {
  background-color: #f6f6f6;
  margin-top: 30px;
  padding: 15px;
}

.input-textbox-field {
  box-sizing: border-box;
  border: 2px solid #e1e1e1;
  border-radius: 0.25rem;
  background-color: #fff;
  font-size: 1.25rem;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  height: 3.4375rem;
  transition: box-shadow 0.2s ease-out;
  width: 62%;
}

.input-textbox-field-width-max {
  width: 25%;
}

.input-textbox-field-width-min {
  width: 20%;
}

.field-yield-harvest {
  background-color: #f6f6f6;
  padding: 15px;
}

.field-grey-header {
  width: 100%;
  background-color: #f6f6f6;
}

.field-plan-content {
  display: grid;
  grid-template-columns: 48% 52%;
  width: 100%;

  .fertilization-plan-grid {
    width: 100%;
    padding: 20px;
  }

  .text-capitalize {
    text-transform: capitalize;
  }

  .fertilization-plan-grid-blue-border {
    border-right: 1px solid #bce2f2;
    width: 100%;
    padding: 20px;
  }

  .fertilization-plan-grid-grey-border {
    border-right: 1px solid #e5e5e5;
    width: 100%;
    padding: 20px;
  }

  .--grid-layout {
    display: grid;
    grid-template-columns: (38% 22% 22% 20%);
    width: 100%;
    justify-content: space-between;
  }

  .--grid-layout-menu {
    display: grid;
    grid-template-columns: (44% 23% 10% 23%);
    width: 100%;
    justify-content: space-between;
  }

  .--grid-layout-menu-field {
    display: grid;
    grid-template-columns: (48% 26% 26%);
    width: 100%;
    justify-content: space-between;
  }

  .--grid-layout-season-menu {
    display: grid;
    grid-template-columns: (39% 17% 8% 22% 14%);
    width: 100%;
    justify-content: space-between;
  }

  .--grid-padding-large {
    padding: 20px 20px 10px 00px;
  }

  .--grid-padding-extralarge {
    padding: 20px 20px 20px 35px;
  }

  .--grid-content-padding-extralarge {
    padding: 15px 20px 15px 35px;
  }

  .--grid-padding-left {
    padding-left: 30px;
  }

  .--grid-padding-left-low {
    padding-left: 15px;
  }

  .--grid-padding-low {
    padding: 10px 20px 10px 0px;
  }

  .--border-thick-blue {
    border-bottom: 3px solid #bce2f2;
  }

  .--border-thin-blue {
    border-top: 1px solid #bce2f2;
  }

  .--border-thick-grey {
    border-bottom: 3px solid #e5e5e5;
  }

  .--border-thin-grey {
    border-top: 1px solid #e5e5e5;
  }
}

.manure-details {
  background-color: $page-bg-color;
  padding: 30px 25px;
  margin-top: 13px;

  .insight-data {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    grid-gap: 1.5rem;
  }

  .insight-data-top {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    grid-gap: 1.5rem;
  }

  .harvest-data-top {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  }
}

.insight-data-bottom {
  display: grid;
  grid-template-columns: 21% 21% 21% 21% 16%;
}

.insight-data-new {
  display: grid;
  grid-template-columns: 40% auto auto;
}

.insight-data-new-radio {
  display: grid;
  grid-template-columns: 25% auto auto;
}

.manure-methods {
  background-color: #f6f6f6;
}

.update-field-btn {
  float: right;
}

.field-batch-header {
  p {
    padding-bottom: 30px;
  }

  .select-box-common {
    max-width: 220px;
  }
}

.field-batch-update {
  .manure-details {
    border-top: 1px solid $common-bdr-color;
    margin: 0;
    padding: 30px 0;
  }

  .close-modal {
    margin-right: 30px;
  }
}

.manure-option-right {
  width: 100%;
}

.manure-method-data {
  padding-left: 175px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  grid-gap: 1.5rem;
}

.manure-method-data-value {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-gap: 3rem;
}

.manure-storage-data {
  background-color: #f6f6f6;
  padding: 15px;
  display: flex;
}

.manure-flex {
  //background-color: #ffffff;
  //border: #d1d1d1 1px solid;
  height: auto;
  width: 100%;
  padding: 10px;
  margin-left: 20px;
}

.manure-inline-flex {
  display: flex;
  justify-content: space-between;
}

.manure-heading {
  margin-top: 20px;
  width: 160px;
  padding-right: 0px;
  text-align: right;
}

.pad-right {
  padding-right: 5px;
}

.pad-top {
  padding-top: 7px;
}

.add-manure {
  cursor: pointer;

  .add-icon {
    display: inline-block;
    vertical-align: middle;
    color: $card-new-icon;

    svg {
      width: 23px;
      height: 23px;
    }
  }

  .delete-icon {
    display: inline-block;
    vertical-align: middle;
    padding: 10px 20px 0px 0px;

    svg {
      width: 45px;
      height: 45px;
    }
  }

  a {
    color: $general-text-color;
  }
}

.field-padding-top {
  padding-top: 18px;
}

.field-padding-top-mobile {
  padding-top: 48px;
}


.floatLeft {
  float: left;
}

.spanUnit {
  font-size: 18px;
  float: left;
  padding: 35px 0 0 15px;
}

.topFont {
  font-weight: 600;
  font-size: 0.75rem;
  color: #000000;
  padding-top: 5px;
}

.topFontSum {
  font-weight: 600;
  font-size: 1rem;
  color: #000000;
  padding-top: 5px;
}

.bottomFont {
  font-weight: 600;
  font-size: 1.3rem;
  color: #000000;
}

.harvestText {
  //width: 100%;
  padding-right: 10px;
}

.harvestTextLow {
  //width: 50%;
  padding-left: 10px;
}

.harvestTextHigh {
  //width: 50%;
  padding-left: 50px;
}

.yield-calculation-button-border {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
  border: thin solid rgb(42, 60, 99) !important;
  border-radius: 1.5rem;
  color: rgb(42, 60, 99) !important;
  padding: 0.7rem 1rem;
}

.yield-calculation-button-border-grey {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
  border: thin solid #cccccc !important;
  border-radius: 1.5rem;
  color: #999999 !important;
  background-color: #f1f1f1;
  padding: 0.7rem 1rem;
  cursor: default;
}

.yield-calculation-button-border:hover {
  background-color: #e8f5fa;
}

.yieldPaddingTop {
  padding-top: 20px;
}

.yieldPaddingTopMin {
  padding-top: 12px;
}

.unit-style {
  padding-left: 10px;
  font-size: 18px;
}

.paddingBottom {
  padding-bottom: 20px;
}

.paddingTop {
  padding-top: 12px;
}

.export-shape-button-border {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
  border: thin solid rgb(42, 60, 99) !important;
  border-radius: 1.5rem;
  color: rgb(42, 60, 99) !important;
  padding: 0.575rem 1.2rem;
}

.upload-btn-wrapper-shape input[type='file'] {
  width: 150px !important;
}

.div-width {
  width: 90%;
}

.field-weather-textcontainer {
  padding-left: 25px;
  padding-bottom: 30px;
}

.field-weather-textcontainer-content {
  width: 100%;
  display: flex;
}

.field-weather-span-full-width {
  padding: 10px 10px;
}

.field-weather-span-width {
  min-width: 124px;
  height: 94px;
}

.field-harvest-span-width {
  min-width: 135px;
  height: 94px;
}

.field-weather-span-less-width {
  min-width: 125px;
  height: 94px;
}

.field-weather-span-medium-width {
  min-width: 174px;
  height: 94px;
}

.field-weather-span-temp-width {
  min-width: 170px;
  max-width: 170px;
  height: 94px;
}

.field-weather-span-more-width {
  min-width: 160px;
  height: 94px;
}

.field-weather-span-more-width1 {
  min-width: 185px;
  height: 94px;
}

.field-weather-span-more-width2 {
  min-width: 175px;
  height: 94px;
}

.field-weather-span-harvest-width {
  min-width: 84px;
  height: 94px;
}

.field-weather-span-units-width {
  min-width: 172px;
  height: 94px;
}

.field-weather-span-digest-width {
  min-width: 138px;
  height: 94px;
}

.field-weather-bold {
  font-weight: 800;
  background-color: #f1f1f1;
}

.field-weather-no-border-top {
  border-top: 0px !important;
}

.field-weather-no-border-right {
  border-right: 0px !important;
}

.weather-table-td-bottom-right {
  padding: 10px;
  border-bottom: 1px solid #c1c1c1;
  border-right: 1px solid #c1c1c1;
}

.weather-table-td-left {
  border-left: 1px solid #c1c1c1;
}

.weather-table-td-top {
  border-top: 1px solid #c1c1c1;
}

.weather-text-bold {
  font-weight: bold;
}

.weather-text-div {
  max-width: 1120px;
  overflow-x: auto;
}

.apexcharts-xaxis-title-text {
  translate: 0px 135px !important;
}

.div-flex-width {
  width: 100%;
}

.field-area-centre {
  height: 30px;
}

.markall-padding {
  padding-left: 9px !important;
}

.padding-bottom-15px {
  padding-bottom: 15px;
}

.width-50-percent {
  width: 50% !important;
}