.error-message {
  float: right;
  margin: 10px 30px 0 0;
  color: #CC0000;

  span {
    float: right;
  }
}

.tm-filter {
  float: left;

  .total-task {
    float: left;
    margin: 16px 0 0;

    span {
      float: left;
      padding-right: 5px;
    }
  }

  .total-area {
    float: left;
    margin: 16px 0 0;

    span {
      float: left;
      padding-right: 5px;
      padding-left: 20px;
    }
  }

  .filter-dropdown {
    float: left;
    width: 171px;
  }

  .filter-batch-dropdown {
    float: left;
    padding-top: 8px;
    width: 200px;
  }

  .filter-dd-lbl {
    float: left;
    margin: 16px 5px 0 0;
  }

  .tm-filter-sortby {
    float: left;
    margin-left: 20px;
    position: relative;
  }

  .tm-filter-batch-filter {
    float: left;
    margin-right: 20px;
    position: relative;
  }

  @include untilTablet() {
    width: 100%;
    margin: 15px 0 0;
  }

  @include break(800px) {
    width: 100%;

    .filter-dropdown {
      clear: both;
    }

    .tm-filter-sortby {
      float: right;
      margin: 10px 0 0;
    }

    .tm-filter-sortby.sort-by {
      clear: both;
      float: left;
    }
  }

  @include break($mobile-screen) {
    .tm-filter-sortby {
      float: left;
      margin: 10px 0 0;
    }
  }

  @include break(1260px) {
    margin-top: 15px;
  }
}

.export-task {
  float: right;
  margin: 20px 30px 0 0;
}

.task-filter {
  float: right;
  margin: 17px 30px 0 0;
  padding: 6px 8px 6px 8px;
}

.task-filter-selected {
  position: relative;
  background: #f1f1f1 !important;
  border-radius: 5px;
  padding: 6px 15px 6px 15px;
}

.task-filter-selected::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -0.4em;
  bottom: -1.2em;
  left: 38%;
  box-sizing: border-box;
  border: 1em solid black;
  border-color: transparent transparent #f1f1f1 #f1f1f1;
  transform-origin: 0 0;
  transform: rotate(-45deg);
}

.select-box-small {
  float: right;
  margin: 5px 0 7px 10px;
  min-width: 180px;
}

.select-box-small-margin {
  margin: 5px 0 7px 0px;
}

.filter-reset-padding {
  padding-top: 15px;
  padding-right: 10px;
  padding-left: 10px;
}

.task-float-right {
  float: right;
}

.task-filter-content {
  background-color: #f1f1f1;
  margin: 20px 15px 0px 15px;

  .item-right {
    padding: 20px;
    float: right;
    width: 100%;
    color: #ee1148;
    font-size: 0.8rem;
  }

  .items-container {
    display: flex;

    .boldFont {
      font-weight: 700;
      padding-left: 20px;
      padding-right: 20px;
    }

    .item {
      color: $general-text-color;
      font-size: 0.8rem;
      width: 31%;
      padding-right: 20px;

      >span {
        display: block;
        margin-top: 1.1rem;

        span {
          float: right;
          margin: -1px 0 0 5px;
        }
      }
    }
  }
}

@include break($mobile-screen) {
  .task-filter-content {
    background: none;
    margin: 20px 0px 0px 0px;

    .item-right {
      float: left;
      color: #ee1148;
      font-size: 0.8rem;
    }

    .items-container {
      display: block;
      float: left;

      .boldFont {
        font-weight: 700;
      }

      .item {
        display: block;
        float: left;
        color: $general-text-color;
        font-size: 0.8rem;
        width: 100%;

        >span {
          display: block;
          float: left;

          span {
            display: block;
          }
        }
      }
    }
  }

  .task-float-right {
    float: left;
  }

  .select-box-small {
    float: left;
  }
}

@include break($tablet-screen) {
  .task-filter-content {
    background: none;
    margin: 20px 0px 0px 0px;

    .item-right {
      float: left;
      color: #ee1148;
      font-size: 0.8rem;
    }

    .items-container {
      display: block;
      float: left;

      .boldFont {
        font-weight: 700;
      }

      .item {
        display: block;
        float: left;
        color: $general-text-color;
        font-size: 0.8rem;
        width: 100%;

        >span {
          display: block;
          float: left;

          span {
            display: block;
          }
        }
      }
    }
  }

  .task-float-right {
    float: left;
  }

  .select-box-small {
    float: left;
  }
}

.link-add {
  float: right;
  margin-top: 20px;

  a {
    color: $card-new-icon;
  }

  div {
    float: left;
  }

  span {
    float: left;
    font-weight: $font-light;
    margin-right: 10px;
  }

  &.disable {
    a {
      pointer-events: none;
      color: $btn-disabled-bg;
    }
  }
}

.link-add-soil {
  float: right;

  a {
    color: $card-new-icon;
  }

  div {
    float: left;
  }

  span {
    float: left;
    margin-right: 10px;
  }

  &.disable {
    a {
      pointer-events: none;
      color: $btn-disabled-bg;
    }
  }
}

.link-add-grass {
  float: left;
  margin-top: 5px;

  a {
    color: $card-new-icon;
  }

  div {
    float: left;
  }

  span {
    float: left;
    font-weight: bold;
    margin-left: 60px;
  }

  &.disable {
    a {
      pointer-events: none;
      color: $btn-disabled-bg;
    }
  }
}

.task-list-wrapper {
  padding: 0 10px;
}

grass-edit-screen {
  float: left;
  font-weight: 900;
}

.task-list-item {
  display: inline-flex;
  flex-basis: 100%;
  margin-bottom: 7px;
  padding: 7px;
  max-width: 100%;

  &:hover {
    background: $task-hover;

    .checkbox-holder span {
      display: block;
    }
  }

  &.show-checkbox {
    .checkbox-holder span {
      display: block;
    }
  }

  .checkbox-holder {
    position: relative;
    width: 65px;

    span {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      @include translateXY(-50%, -50%);
    }

    @include break($tablet-screen) {
      width: 35px;

      span {
        display: block;
      }
    }
  }

  .task-link {
    background: $task-bg;
    border: 1px solid $task-list-bdr;
    border-left: 4px solid $task-grey-indicator;
    color: $general-text-color;
    float: left;
    padding: 10px 10px 10px 20px;
    width: calc(100% - 65px);

    &.grey-bdr {
      border-left: 4px solid $task-grey-indicator;
    }

    &.green-bdr {
      border-left: 4px solid $task-green-indicator;
    }

    @include break($tablet-screen) {
      width: calc(100% - 35px);
    }
  }

  .task-link-field {
    background: $task-bg;
    border: 1px solid $task-list-bdr;
    border-left: 4px solid $task-grey-indicator;
    color: $general-text-color;
    float: left;
    padding: 10px 10px 10px 20px;
    width: 100%;

    &.grey-bdr {
      border-left: 4px solid $task-grey-indicator;
    }

    &.green-bdr {
      border-left: 4px solid $task-green-indicator;
    }

    @include break($tablet-screen) {
      width: calc(100% - 35px);
    }

    @include break($mobile-screen) {
      width: 100%;
    }
  }

  .task-details {
    display: inline-flex;
    flex-basis: 100%;
    width: 100%;

    .task-assignee,
    .task-status,
    .task-status-field,
    .task-field-image {
      flex: 1;
    }

    .task-field-image {
      min-width: 30%;
      padding-left: 10px;

      .task-filed-tile {
        float: left;
        width: 100%;
        margin-top: 18px;
      }

      .field-name {
        font-weight: $font-light;
        margin: 33px 15px 0 0;
        height: 20px;
        width: calc(100% - 5px);
      }
    }

    .task-assignee,
    .task-status,
    .task-status-field,
    .task-field-image {
      margin: 5px 0 0;
      padding: 0 15px;
    }

    .task-right-border {
      border-right: 1px solid $common-bdr-color;
    }

    .task-status {
      max-width: 150px;

      &.status {
        .item-value {
          text-transform: capitalize;
        }
      }
    }

    .task-status-field {
      max-width: 200px;

      &.status {
        .item-value {
          text-transform: capitalize;
        }
      }
    }

    .task-assignee {
      min-width: 30%;
      max-width: 300px;
    }

    @include break($tab-screen-min) {
      display: block;
      position: relative;

      .task-assignee,
      .task-status,
      .task-status-field,
      .task-field-image {
        float: left;
      }

      .task-assignee {
        min-width: 100%;
        max-width: 100%;
      }

      .task-field-image {
        width: 100%;
      }

      .task-status {
        width: 33.33%;
        margin: 15px 0 0;
        max-width: 33.33%;

        &.status {
          clear: both;
        }

        &.amount {
          border-right: none;
        }
      }

      .task-status-field {
        width: 33.33%;
        margin: 15px 0 0;
        max-width: 33.33%;

        &.status {
          clear: both;
        }

        &.amount {
          border-right: none;
        }
      }
    }

    @include break($mobile-screen) {
      display: block;
      position: relative;

      .task-assignee,
      .task-status,
      .task-status-field,
      .task-field-image {
        float: left;
      }

      .task-assignee {
        min-width: 100%;
        max-width: 100%;
      }

      .task-field-image {
        width: 100%;
      }

      .task-status {
        width: 100%;
        margin: 15px 0 0;
        max-width: 100%;

        &.status {
          clear: both;
        }

        &.amount {
          border-right: none;
        }
      }

      .task-status-field {
        width: 100%;
        margin: 15px 0 0;
        max-width: 100%;

        &.status {
          clear: both;
        }

        &.amount {
          border-right: none;
        }
      }
    }

    .leaflet-bottom {
      display: none;
    }
  }

  .task-name {
    float: left;
    width: 100%;

    .icon-record {
      color: $task-ongoing-indicator;
      float: left;
      font-size: 0.75rem;
      margin: 5px 10px 0 0;
    }

    span {
      float: left;
      padding-right: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 23px);
    }
  }

  .assignee-name {
    clear: both;
    float: left;
    margin-top: 10px;
    width: 100%;

    .profile-img {
      float: left;
    }

    .profile-name {
      float: left;
      font-size: $base-font-size;
      font-weight: $font-light;
      margin: 3px 0 0 10px;
      width: calc(100% - 36px);
    }
  }

  .item-lbl {
    float: left;
    font-size: 12px;
    font-weight: $font-light;
  }

  .item-value {
    clear: both;
    float: left;
    font-weight: $font-semi-bold;
    margin-top: 18px;
    width: 100%;
  }

  .item-value1 {
    clear: both;
    float: left;
    font-weight: $font-semi-bold;
    margin-top: 12px;
    width: 100%;
  }
}

.task-list-update {
  background: $task-patch-bg;
  bottom: 10px;
  position: fixed;
  width: 83%;
  padding: 40px 60px;
  z-index: 6;

  .selected-task {
    float: left;
    font-size: 16px;
    font-weight: $font-light;
    margin-right: 50px;

    span {
      font-weight: $font-bold;
      padding: 0 5px;
    }
  }

  button {
    float: left;
    margin-right: 15px;
  }

  .delete-tasks {
    float: right;

    button {
      background: $user-trash-bg;
      float: right;
      height: 34px;
      width: 34px;
      border-radius: 50%;
      margin: -5px 0 0 10px;

      svg {
        fill: $btn-primary-color;
        margin: 1px 0 0px -2px;
      }
    }

    span {
      float: left;
      font-weight: $font-light;
      font-size: 12px;
      margin-top: 5px;
    }
  }

  @include break($navbar-break) {
    width: 100%;
    padding: 15px 20px;

    button {
      clear: both;
      margin: 20px 0 10px;
    }

    .select-box-common {
      float: right;
      margin: 20px 0 10px 15px;
    }

    .selected-task {
      font-size: 12px;
      width: 100%;
    }

    .delete-tasks {
      position: absolute;
      top: 10px;
      right: 20px;

      span {
        display: none;
      }
    }
  }
}

.task-list-container {
  position: relative;
  padding: 20px 0;
  max-width: 100%;
  color: $task-grey-indicator;

  .task-list-divider {
    padding: 23px 17px 8px 83px;

    .divider-line {
      background: $common-bdr-color;
      height: 1px;
      position: absolute;
      top: 50%;
      width: 100%;
      z-index: -1;
    }

    &:first-of-type {
      padding: 3px 17px 8px 83px;
    }
  }

  .task-list-divider-elm {
    position: relative;
  }

  .task-list-name {
    background: $page-bg-color;
    font-weight: $font-semi-bold;
    left: 50%;
    padding: 0 20px;
    position: absolute;
    text-transform: capitalize;
    @include translateX(-50%);
  }

  .activity-count {
    background: $page-bg-color;
    font-size: 12px;
    padding-right: 13px;
    text-transform: lowercase;
  }

  .task-list-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    @include translateXY(-50%, -50%);
  }
}

.confirm-msg-wrapper {
  min-width: 200px;
  margin-top: 4px;

  p {
    padding: 5px 0;
  }
}

.completed-assign-msg {
  text-align: center;

  p {
    padding: 10px 0;
  }
}

.load-more-wrapper {
  padding: 30px 0;
  text-align: center;
}

.padding-left-small {
  padding-left: 5px;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.form-group {
  padding-bottom: 0px !important;
}

.margin-top-light {
  margin-top: 9px;
}

.field-year {
  font-size: 18px;
  font-weight: 800;
  padding-left: 5px;
}

.task-batch-div {
  margin-top: 30px;
  font-size: 14px;
  font-weight: 800;
}

.task-batch-div-div {
  font-size: 14px;
  font-weight: 800;
  border: 1px solid #cccccc;
  padding: 15px 0px;
}

.task-batch-div-header {
  font-size: 14px;
  font-weight: 800;
  border: 1px solid #cccccc;
}

.task-batch-right-border {
  border-right: 1px solid #cccccc;
}

.task-batch-div-div-no-top {
  font-size: 14px;
  font-weight: 800;
  border-right: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  padding: 15px 0px;
}

.task-batch-padding {
  padding: 8px 8px;
  min-width: 270px;
  max-width: 320px;
  vertical-align: top;
  position: relative;
  background-color: #fff;
}

.task-batch-padding-units {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 20px;
  min-width: 270px;
  max-width: 280px;
  background-color: #fff;
  position: relative;
}

.task-batch-padding-220 {
  padding: 8px 8px;
  min-width: 220px;
  max-width: 220px;
  background-color: #fff;
  position: relative;
}

.task-batch-padding-220-units {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 20px;
  min-width: 220px;
  max-width: 220px;
  background-color: #fff;
  position: relative;
}

.task-batch-padding-230 {
  padding: 8px 8px;
  min-width: 230px;
  max-width: 230px;
  background-color: #fff;
  position: relative;
}

.task-batch-padding-230-units {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 20px;
  min-width: 230px;
  max-width: 230px;
  background-color: #fff;
  position: relative;
}

.task-batch-padding-200 {
  padding: 8px 8px;
  min-width: 200px;
  max-width: 200px;
  background-color: #fff;
  position: relative;
}

.task-batch-padding-200-units {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 20px;
  min-width: 200px;
  max-width: 200px;
  background-color: #fff;
  position: relative;
}

.task-batch-padding-160 {
  padding: 8px 8px;
  min-width: 160px;
  max-width: 160px;
  background-color: #fff;
  position: relative;
}

.task-batch-padding-160-units {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 20px;
  min-width: 160px;
  max-width: 160px;
  background-color: #fff;
  position: relative;
}

.task-batch-padding-180 {
  padding: 8px 8px;
  min-width: 180px;
  max-width: 180px;
  background-color: #fff;
  position: relative;
}

.task-batch-padding-180-units {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 20px;
  min-width: 180px;
  max-width: 180px;
  background-color: #fff;
  position: relative;
}

.task-batch-padding-120 {
  padding: 8px 8px;
  min-width: 120px;
  max-width: 120px;
  background-color: #fff;
  position: relative;
}

.task-batch-padding-130 {
  padding: 8px 8px;
  min-width: 130px;
  max-width: 130px;
  background-color: #fff;
  position: relative;
}

.task-batch-padding-medium {
  padding: 8px 8px;
  min-width: 180px;
  max-width: 180px;
  background-color: #fff;
  position: relative;
}

.task-batch-border-right {
  border-right: 1px solid #cccccc;
}

.task-batch-border-top {
  border-top: 1px solid #cccccc;
}

.task-batch-float-left {
  vertical-align: middle;
}

.task-batch-float-top-zero {
  padding-top: 0px;
}

.task-batch-padding-left {
  padding-left: 15px;
  padding-right: 15px;
  background-color: #fff;
  position: relative;
}

.task-batch-textcontainer {
  padding-top: 10px;
  float: left;
  max-width: 100px;
  padding-top: 90px;
}

.task-batch-div {
  max-width: 1300px;
  overflow-x: auto;
}

.textareafield-placeholder-size {
  height: 50px;
  width: 100%
}

.task-batch-font-size {
  font-size: 14px;
  font-weight: 400;
}

.input-textbox-task-batch {
  box-sizing: border-box;
  width: 100%;
  border: thin solid #cccccc;
  border-radius: 0.25rem;
  background-color: #fff;
  font-weight: 400;
  font-size: 0.9rem;
  font-family: Open Sans, sans-serif;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 38px;
  transition: box-shadow 0.2s ease-out;
  float: left;
}

.input-content-task-batch {
  width: 100%;
  font-weight: 400;
  font-size: 1.2rem;
  font-family: Open Sans, sans-serif;
  text-align: left;
  padding-left: 10px;
  padding-right: 10px;
  min-height: 38px;
  float: left;
}

.task-batch-width-change {
  width: 70%;
}

.task-batch-units-space {
  float: left;
  padding-top: 8px;
  padding-left: 5px;
}

.user-dropdown-normal {
  .profile-img {
    float: right;
    font-size: 22px;
    margin-left: 5px;
    align-items: center;
    display: flex;
  }

  .user-name {
    float: left;
    width: calc(100% - 23px);
    @extend .ellipsis-text;
  }

  .css-xo7z33-option,
  .css-fk865s-option,
  .css-dpec0i-option {
    float: left;
    clear: both;
    width: 100%;
    padding: 12px 20px;
    display: flex;
  }

  .css-dvua67-singleValue {
    width: 100%;
    display: flex;
    padding: 0 18px;
  }
}

.padding-top-no-content {
  padding-top: 30px;
}

table.scrolldown {
  width: 100%;
}

table.scrolldown tbody,
table.scrolldown thead {
  display: inline;
}

thead.scrolldown tr th {
  height: 50px;
  line-height: 40px;
}

table.scrolldown tbody {
  max-height: 380px;
  overflow-y: auto;
  float: left;
  overflow-x: hidden;
}

.fixed-button-task {
  width: 100%;
  float: left;
}

.padding-right-heavy-task {
  padding-right: 40px;
  padding-top: 5px;
  padding-bottom: 40px;
  float: left;
}

.font-popup-success {
  font-size: 1.1rem
}

.batch-task-save-button {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 0.9rem;
  cursor: pointer;
  border: thin solid #2a3c63 !important;
  background-color: #2a3c63;
  border-radius: 1.5rem;
  color: #ffffff !important;
  padding: 0.55rem 2rem;
}

.select-box-common-pest {
  div {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
}

.feedback {
  padding: 5px 20px;
  border: 0;
  background: none;
}

.mybutton {
  position: fixed;
  bottom: 25px;
  right: 10px;
  z-index: 1;
}

.padding-task-batch-css {
  padding-top: 115px;
}

.position-task-batch-css {
  position: fixed;
  width: 100%;
  z-index: 3;
}

.float-task-batch-css {
  float: left !important;
  padding-left: 70%;
}

.task-batch-text-align-left {
  text-align: left;
}

.table-fixed-div {
  table {
    tr>th:first-child,
    tr>td:first-child {
      position: sticky;
      left: -1rem;
      background-color: #fff;
      z-index: 2;
      vertical-align: top;

      @include break($tablet-screen) {
        left: 0rem;
      }

      @include break($mobile-screen) {
        left: 0rem;
      }
    }
  }
}

.customized-select-dropdown {
  padding: 10px 10px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  height: 54px;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.customized-select-dropdown-min-width {
  min-width: 270px;
}

.dropdown-padding {
  padding-top: 3px;
}

.tm-filter-template {
  float: left;
  position: fixed;
  padding-left: 30px;
  padding-top: 20px;

  .total-task {
    float: left;
    margin: 16px 0 0;

    span {
      float: left;
      padding-right: 5px;
    }
  }

  .total-area {
    float: left;
    margin: 16px 0 0;

    span {
      float: left;
      padding-right: 5px;
      padding-left: 20px;
    }
  }

  .filter-dropdown {
    float: left;
    width: 171px;
  }

  .filter-batch-dropdown {
    float: left;
    padding-top: 8px;
    width: 200px;
  }

  .filter-dd-lbl {
    float: left;
    margin: 16px 5px 0 0;
  }

  .tm-filter-sortby {
    float: left;
    margin-left: 20px;
    position: relative;
  }

  .tm-filter-batch-filter {
    float: left;
    margin-right: 20px;
    position: relative;
  }

  @include untilTablet() {
    width: 100%;
    margin: 15px 0 0;
  }

  @include break(800px) {
    width: 100%;

    .filter-dropdown {
      clear: both;
    }

    .tm-filter-sortby {
      float: right;
      margin: 10px 0 0;
    }

    .tm-filter-sortby.sort-by {
      clear: both;
      float: left;
    }
  }

  @include break(1260px) {
    margin-top: 15px;
  }
}

.template-width {
  width: 240px;
}