@import 'variables';
@import 'helpers';

.reporting-wrapper{
    max-width: 340px;
    position: relative;
    float: left;
    @include break($laptop-screen) { 
        min-height: 700px;
    }
    @include break($mobile-screen) { 
        min-height: 400px;
    }
    .reporting-text {
        padding-top: 31px;
        padding-left: 42px;
        max-width: 315px;
        float: left;
        & > p{
            color: $general-text-color;
            font-size: 14px;
            font-weight: 300;
            line-height: 19px;
        }
    }
    .reporting-options{
        padding: 30px 0 0;
        .form-group{
            padding: 0 0 0 42px;
            width: 100%;
            .select-field{
                height: 52px;
                width: 171px;
                border: 1px solid #CCCCCC;
                @include bdr-radius(2px);
                padding: 10px;
            }
        }
        .time-select{
            margin-top: 20px;
        }
    }
    .export-wrapper{
        margin: 15px 0 40px 0;
        text-align: center;
        .report-error{
            margin: 10px 0;
            position: relative;
            text-align: left;
            padding-left: 42px;
        }
        .error{
            color: $failure-color;                
        }
        .success{
            color: $success-color;
        }
        button {
            float: right;
        }
    }
    .report-loader{
        position: absolute;
        left: 40%;
        top: 50%;
    }
}
.downloadReportLink {
    visibility: hidden;
}