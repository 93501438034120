.form-group {
  padding-bottom: 15px;
  position: relative;
  @extend .full-width;

  label {
    text-transform: initial;

    &.custom-lbl {
      margin-bottom: 0.1875rem;
      float: left;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 21px;
    }
  }

  .error-message {
    color: $failure-color;
    font-style: italic;
    text-align: left;
    font-size: 0.875rem;
    padding: 3px 0 0;
  }

  .css-1pcexqc-container,
  .select-box-common {
    @extend .full-width;

    &:focus {
      outline: none;
    }

    .select-option--checkbox {
      position: absolute;
      z-index: 1;
      right: 15px;
    }

    .select-option--label {
      display: block;
      padding-right: 25px;
      position: relative;
      z-index: 2;
    }
  }

  .css-1szy77t-control {
    &:hover {
      border-color: $input-bdr-color;
    }

    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    border-color: $input-bdr-color;
  }
}

.checkbox-control {
  float: left;
  height: 21px;
  width: 21px;
  cursor: pointer;

  &:focus,
  span {
    outline: none;
  }

  input[type='checkbox'] {
    display: none;

    &+span {
      background: $checkbox-tick-color;
      border: 1px solid $checkbox-color;
      @include bdr-radius(4px);
      cursor: pointer;
      float: left;
      font-family: 'eanaicons';
      font-size: 20px;
      height: 21px;
      margin: 0px 4px 0 0;
      position: relative;
      width: 21px;
      vertical-align: middle;
    }

    &:checked+span {
      background: $checkbox-color;
      color: $checkbox-tick-color;

      &::before {
        content: '\e903';
      }
    }
  }

  .checkbox-label {
    float: left;
    font-size: $lbl-font-size;
    font-weight: $lbl-font-weight;
    margin: 3px 0 0 8px;
  }
}

.upload-btn-wrapper {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: inline-block;

  input[type='file'] {
    font-size: 40px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}

.upload-btn-wrapper-shape {
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-left: 20px;

  input[type='file'] {
    font-size: 40px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
  }
}

.select-error {
  .select-box-common {

    .css-bg1rzq-control,
    .css-1szy77t-control {
      border: 1px solid $failure-color;
    }
  }
}

.select-box-common,
.select-box-field {

  .css-bg1rzq-control,
  .css-1szy77t-control {
    background: $dropdown-bg;
    border: 1px solid $dropdown-bdr;
    border-radius: 3px;
    box-shadow: none;
    cursor: pointer;
    min-height: 54px;
    outline: none;
  }

  .css-foqyaq-control {
    border-radius: 2px 2px 0 0;
    min-height: 54px;
    outline: none;

    .css-ue83k8-singleValue {
      padding: 0 20px;
    }
  }

  .css-14ojxsb-control {
    border: 1px solid $dropdown-bdr;
    border-radius: 2px 2px 0 0;
    box-shadow: none;
    min-height: 54px;
    outline: none;
    width: 100%;

    .css-ue83k8-singleValue {
      padding: 0 20px;
    }
  }

  .css-1hwfws3 {
    padding: 0;

    .css-151xaom-placeholder {
      padding: 15px 20px;
    }
  }

  .css-1g6gooi {
    padding-left: 20px;
  }

  .css-dvua67-singleValue {
    color: $general-text-color;
    font-size: 14px;
    max-width: calc(100% - 20px);
    padding: 0 20px;
  }

  .css-1thkkgx-indicatorContainer,
  .css-16pqwjk-indicatorContainer {
    padding: 0;
    color: $general-text-color;

    span {
      font-size: 12px;
    }
  }

  .css-1wy0on6 {
    background: transparent;
    margin: 5px 10px 0 0;
  }

  .css-kj6f9i-menu {
    background: $dropdown-bg;
    border: 1px solid $dropdown-bdr;
    border-top: 0;
    border-radius: 0 0 2px 2px;
    box-shadow: none;
    margin-top: 0;
    z-index: 2;
  }

  .css-11unzgr {
    padding-top: 0;
  }

  .css-xo7z33-option,
  .css-fk865s-option,
  .css-dpec0i-option {
    color: $general-text-color;
    cursor: pointer;
    padding: 15px 20px;
    position: relative;
    word-break: break-all;

    &::before {
      content: '';
      position: absolute;
      height: 1px;
      background: $dropdown-item-bdr;
      width: 90%;
      bottom: 0;
      z-index: 1;
      left: 5%;
    }
  }

  .css-xo7z33-option {
    background: $dropdown-item-active-bg;

    &:active {
      background: $dropdown-item-active-bg;
    }
  }

  .css-fk865s-option {
    background: $dropdown-item-bg;
  }

  &.user-dropdown {
    float: left;
    width: 150px;

    .profile-img {
      float: right;
      font-size: 16px;
      margin-left: 5px;
    }

    .user-name {
      float: left;
      width: calc(100% - 23px);
      @extend .ellipsis-text;
    }

    .css-xo7z33-option,
    .css-fk865s-option,
    .css-dpec0i-option {
      float: left;
      clear: both;
      width: 100%;
    }

    .css-1wy0on6 {
      display: none;
    }

    .css-dvua67-singleValue {
      padding: 0;
      font-size: 0.75rem;
      margin: 0;
      font-weight: 400;
      color: #2a3c63;
      width: 100%;
      max-width: 100%;
    }

    .css-151xaom-placeholder {
      padding: 2px 0 0;
      width: calc(100% - 5px);
      color: #2a3c63;
    }

    .css-1g6gooi {
      font-size: 0.75rem;
      padding: 0;
    }

    .css-bg1rzq-control,
    .css-1szy77t-control {
      padding: 0.15rem 1rem;
      font-size: 0.75rem;
      font-weight: 400;
      text-decoration: none;
      cursor: pointer;
      border: thin solid #2a3c63;
      border-radius: 1.5rem;
      background-color: #fff;
      height: auto;
      min-height: auto;

      &:hover {
        background: #e8f5fa;
      }

      &:focus {
        outline: 0;
        box-shadow: #209fd5 0 0 0 0.125rem;
      }
    }
  }

  &.user-dropdown-normal {
    .profile-img {
      float: right;
      font-size: 22px;
      margin-left: 5px;
      align-items: center;
      display: flex;
    }

    .user-name {
      float: left;
      width: calc(100% - 23px);
      @extend .ellipsis-text;
    }

    .css-xo7z33-option,
    .css-fk865s-option,
    .css-dpec0i-option {
      float: left;
      clear: both;
      width: 100%;
      padding: 12px 20px;
      display: flex;
    }

    .css-dvua67-singleValue {
      width: 100%;
      display: flex;
      padding: 0 18px;
    }
  }
}

.disable-input input {
  background-color: #ebebe4;
}