@import 'variables';
@import 'helpers';

.help-text__wrapper {
  background-color: $app-shade-light;
}

.help-text__wrapper {
  padding: 3.125rem 3.125rem 0;
}

.help-text__title {
  font-size: 1rem;
  font-weight: $font-semi-bold;
  padding: 5px 0 20px;
}

.help-text__points-wrap {
  padding: 20px 0 0;

  &>p {
    padding: 0 3.125rem;
  }
}

.help-text__list {
  li {
    background-color: #cae3e1;
    padding: 15px 3.125rem 15px 4.5rem;
    position: relative;
    margin-bottom: 10px;

    span {
      &:first-child {
        position: absolute;
        left: 3.125rem;
        top: 9px;
      }
    }
  }
}

.yt-video {
  p {
    text-align: center;
  }
}

.feedback-btn {
  padding: 20px 0 30px;
  text-align: center;
}

.help-text {

  [class^="styled-components__StyledContent"],
  [class*=" styled-components__StyledContent"] {
    background-color: $app-shade-light;
    margin: 0;
  }

  [class^="styled-components__StyledDialog"],
  [class*=" styled-components__StyledDialog"] {
    background-color: $app-shade-light;
    border: 10px solid $app-dark;
  }

  .help-close {
    background-color: $secondary;
    border-radius: 50%;
    fill: $white;
    padding: 9px 14px;
    margin-right: 20px;

    &:hover {
      background-color: $secondary-shade-medium;
    }

    &:active {
      background-color: $secondary-shade-dark;
    }
  }

}

.help-feedback {

  .btn-back {
    fill: $link-color;
    margin: 20px 0 0 20px;

    span {
      padding-left: 10px;
    }
  }

}

.help-feedback__wrap {
  margin: 3.14rem auto;
  max-width: 450px;
  min-width: 200px;

  form {
    padding: 0 20px;

    button {
      clear: both;
      float: right;
      margin-bottom: 30px;
    }
  }
}

.help-feedback__success {
  div {
    display: block;
    margin: 20px 0;
    text-align: center;

  }

  .success-indicator {
    float: none;
    font-size: 36px;
  }
}

.feedback-title {
  text-align: center;
  padding: 20px 0;
}