@import 'variables';
@import 'helpers';

.login-page-wrapper {
    display: flex;
    flex-direction: row;

    @include break(1024px) {
        display: initial;

        .login-container,
        .signup-container {
            clear: both;
            display: initial;
            float: left;
            padding: 0 15px;
            width: 100%;
            min-height: auto;
        }

        .eana-bar {
            background: $bar-bg-mbl;
            height: 10px;
            min-height: 10px;
            width: 100%;
        }
    }

    .newtork-message {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
    }

    .eana-app-image {
        float: left;
        height: 50px;
        width: 50px;
    }
}

.eana-bar {
    background: $bar-bg;
    min-height: 100vh;
    width: 10px;
}

.login-container,
.signup-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: $signup-bg;
}

.eanaLogo {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    @include break($mobile-screen) {
        align-items: center;
        padding: 48px 24px 24px 24px;
    }
}

.logoBelowContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
}

.logoRightContent {
    display: flex;
    padding: 24px 24px 24px 64px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
    height: 100%;
    border-radius: 16px;
    background: var(--M3-sys-light-surface, #F7FAF9);
}

/* Media query for mobile screens */
@media (max-width: 640px) {
    .logoRightContent {
        display: flex;
        padding: 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        flex: 1 0 0;
        align-self: stretch;
        height: 100%;
        border-radius: 16px;
        background: var(--M3-sys-light-surface, #F7FAF9);
    }
}

.logoLeftContent {
    display: flex;
    padding: 24px 24px 24px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
    height: 100%;
}

.logoLeftContentNoColor {
    padding: 0;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
    align-self: stretch;
    height: 100%;
    background: var(--M3-sys-light-surface, #f1f1f1);
    border: none;
}

.logoRightContentDiv {
    border-radius: 16px;
    background: var(--M3-sys-light-surface, #F7FAF9);
}

.logoRightContentFirstParagraph {
    align-self: stretch;
}

.firstParagraphText1 {
    color: var(--M3-sys-light-on-surface, #191C1C);
    /* M3/headline/small */
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 133.333% */
}

.firstParagraphText2 {
    color: var(--M3-sys-light-on-surface, #191C1C);
    /* M3/title/medium */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 150% */
    letter-spacing: 0.15px;
    -webkit-font-smoothing: antialiased;
}

.boxDot {
    height: 6px;
    width: 6px;
    background-color: #111;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.firstParagraphText3 {
    color: var(--M3-sys-light-on-surface, #191C1C);
    /* M3/body/large */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
}

.leftParagraphText1 {
    color: var(--M3-sys-light-on-secondary-container, #041F21);
    text-align: center;
    /* M3/headline/medium */
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    /* 128.571% */
}

.leftParagraphText2Div {
    display: flex;
    padding: 22px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
}

.leftParagraphText2 {
    color: var(--M3-sys-light-primary, #00696D);
    text-align: center;
    /* M3/label/large */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.1px;
    cursor: pointer;
    padding-left: '32px';
}

.priceListLink {
    color: var(--M3-sys-light-primary, #00696D);
    /* M3/body/large */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    cursor: pointer;
}

.eanaLeftSideLayout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
}

.eanaLeftSideInsideLayout {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 88px;
    align-self: stretch;
}

.eanaLoginButton {
    display: flex;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.login-container {
    width: 100%;
    padding: 8px;
}

_:-ms-fullscreen,
:root .signup-container {
    min-height: 1040px;
}

@include break($mobile-screen) {

    _:-ms-fullscreen,
    :root .signup-container {
        min-height: auto;
    }

    .footer-login-page {
        position: inherit;
        color: var(--M3-sys-light-on-surface, #191C1C);
        text-align: center;
        font-family: Inter;
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.5px;
    }
}

.signup-container {
    background: $signup-bg;
    width: 50%;
    padding: 8px;
}

/** Logo */
.logo {
    align-self: flex-start;
    color: $logo-color;
    flex: 1;
    font-size: 35px;
    margin: 80px 80px 0;
    min-height: 100px;
    max-height: 100px;

    @include break(1024px) {
        float: left;
        width: 100%;
        min-height: auto;
        margin: 20px 0;
    }
}

/** Login page layout */
.login-wraper {
    flex: 1;

    .app-logo {
        .app-name {
            float: left;
            font-weight: $font-semi-bold;
            padding: 34px 0 0 0px;
        }

        .app-name-content {
            float: left;
            font-weight: 300;
            padding: 34px 0 0 0px;
        }

        .app-font-size {
            font-size: 1.25rem;
        }

        .app-name-center {
            width: 100%;
            text-align: center;
            padding-left: 10%;
            padding-right: 10%;
        }
    }

    @include break(1024px) {
        float: left;
        width: 100%;
        margin: 25px 0;

        .app-logo {
            .app-name {
                padding: 34px 0 0 0px;
            }

            .app-name-content {
                padding: 34px 0 0 0px;
            }

            .app-font-size {
                font-size: 1.25rem;
            }

            .app-name-center {
                width: 100%;
                text-align: center;
                padding-left: 10%;
                padding-right: 10%;
            }
        }
    }

}

.login-wrapper-width {
    width: 62%;

    @include break(600px) {
        width: 100%;
    }
}

/** Login Form  */
.login-form-container {
    @include break(1024px) {
        max-width: 300px;
        margin: 0 auto;
    }

    @include break(600px) {
        max-width: initial;
        margin: 0;
    }

}

/** Login Form  */
.newlogin-form-container {
    @include break(1024px) {
        max-width: 600px;
        margin: 0 auto;
    }

    @include break(600px) {
        max-width: initial;
        margin: 0;
    }

}

.login-form {
    /*padding: 35px 0;*/
    position: relative;
    min-width: 300px;

    .remember-checkbox {
        clear: both;
        float: left;
        margin-top: 6px;
        width: auto;
    }

    .btn-wapper {
        text-align: center;
        padding: 40px 0 20px;
        @extend .full-width;

        div {
            margin-top: 20px;
            font-weight: $font-semi-bold;
        }
    }

    .btn-wapper-new {
        text-align: center;
        padding: 70px 0 0 0;
        @extend .full-width;

        div {
            margin-top: 20px;
            font-weight: $font-semi-bold;
        }
    }

    .btn-wapper-new1 {
        text-align: center;
        padding: 40px 0 0 0;
        @extend .full-width;

        div {
            margin-top: 20px;
            font-weight: $font-semi-bold;
        }
    }

    .error-text {
        clear: both;
        padding-top: 20px;
        display: block;
        max-width: 300px;

        /*&.sign-in-error {
            bottom: 90px;
        }*/
    }

    .login-loader {
        position: absolute;
        bottom: 120px;
        left: 42%;
    }

    .signup-loader {
        position: absolute;
        bottom: 80px;
        left: 40%;
    }

    @include break($mobile-screen) {
        padding: 20px 0;
    }
}

/** Forgot password */
.forgot-password {
    padding: 35px 20px;
    position: relative;

    .form-title {
        font-weight: $font-semi-bold;
        padding-bottom: 40px;
    }

    .form-group {
        width: 300px;
    }

    .sent-notify {
        @extend .full-width;
        padding: 20px 0 0;

        span {
            float: left;
            font-style: italic;
            padding: 5px 0 0 10px;
        }
    }

    .error-text {
        clear: both;
        float: left;
        max-width: 300px;
        margin: 5px 0 0;
    }

    .btn-wapper {
        @extend .full-width;
        padding: 50px 0 30px;

        a {
            float: left;
        }

        button {
            float: right;
        }
    }

    .pwd-align {
        padding-left: 15px;
        padding-right: 15px;
    }

    .login-loader {
        bottom: 90px;
        left: 39%;
        position: absolute;
    }
}

/** Change password */
.change-pwd-block {
    background: $signup-bg;
    margin-top: 28px;
    padding: 13px 15px 20px;
    @include bdr-radius(2px);

    p {
        font-size: 12px;
        font-weight: $font-light;
        line-height: 20px;

        &.top-align {
            margin-top: 12px;
        }
    }

    .form-group .error-message {
        clear: both;
        float: right;
        max-width: 300px;
        position: static;
    }

    &.sign-up {
        margin-bottom: 8px;
        max-width: 331px;
    }
}

/** Sign up block */
/** .logo-mimiro {
    align-self: flex-start;
    flex: 1;
    font-size: 70px;
    margin: 65px 0 0 60px;
    max-height: 230px;
    min-height: 100px;
    @include break(1024px) {
        margin: 20px 0 0 20px;
    }
    @include break($mobile-screen) {
        float: left;
        margin: 20px 15px;
        width: 100%;
    }
} **/
.signup-content {
    flex: 1 1;
    padding: 20px 60px;
    width: 100%;
    border-radius: 20px;
    background-color: #fff;

    @include break(1024px) {
        padding: 30px 20px;
    }

    .signup-heading {
        padding-bottom: 4px;
    }

    p {
        font-weight: $font-light;
        padding-bottom: 25px;
    }

    h4 {
        font-weight: $font-semi-bold;
        padding-bottom: 10px;
    }

    .signupBnt {
        float: left;
        margin: 20px 0;
    }
}

.register-form {
    padding: 0 15px;

    .form-title {
        font-weight: $font-semi-bold;
        padding-bottom: 23px;
    }

    .form-group {
        width: 300px;
    }
}

.read-terms {
    float: right;
    font-size: 12px;
    margin: 8px 0 0;
}

.modal-content {
    color: $terms-heading-color;
    max-width: 615px;
    padding: 20px 15px 0;

    h2 {
        font-size: 16px;
    }

    p {
        font-weight: $font-semi-bold;
        padding: 20px 0 0;
    }

    ul {
        li {
            font-weight: $font-semi-bold;
            list-style: disc inside none;
            padding: 5px 15px;
        }
    }

    .btn-wapper {
        margin: 35px 0;
        text-align: center;
        @extend .full-width;

        button {
            margin: 15px 15px 0;
        }
    }

    &.full-tc {
        color: $general-text-color;
        padding: 20px 0 0;

        .full-tc-wrapper {
            max-height: 560px;
            overflow-y: scroll;
        }

        p,
        li {
            font-size: 12px;
            font-weight: normal;
        }

        h2 {
            font-size: 12px;
            font-weight: $font-semi-bold;
            margin-top: 20px;
            text-transform: uppercase;

            &.full-tc-heading {
                font-size: 14px;
                margin: 10px 0 0;
            }
        }
    }

    &.no-thanks {
        min-width: 615px;

        @include break(1024px) {
            min-width: auto;

            .no-thanks-content .btn-wapper {
                button {
                    float: none;
                    margin: 15px 15px 0;
                }

                p {
                    padding: 20px 0 0;
                }

                h2 {
                    padding: 0;
                }
            }
        }
    }

    .no-thanks-content {
        color: $general-text-color;
        max-width: 340px;
        margin: 0 auto;

        p {
            font-size: 12px;
            font-weight: normal;
            padding: 20px 20px 0;
        }

        h2 {
            font-size: 14px;
            font-weight: $font-semi-bold;
            padding: 0 20px;
        }

        .btn-wapper {
            button {
                margin: 15px 0 0;
                float: right;

                &:first-child {
                    float: left;
                }
            }
        }
    }
}

.full-tc-link {
    font-weight: $font-semi-bold;
    float: left;
    padding: 20px 0 0;
}

.styles_overlay__CLSq- {
    background: rgba(0, 0, 0, 0.3);
}

.go-to-login-button {
    cursor: pointer;
    border: thin solid #00696D !important;
    border-radius: 1.5rem;
    color: #ffffff !important;
    background-color: #00696D !important;
    padding: 15px 30px;
    color: var(--M3-sys-light-on-primary, #FFF);
    text-align: center;
    /* M3/label/large */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.1px;
}

.go-to-login-button-link {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 0.9rem;
    cursor: pointer;
    color: #197479 !important;
}

.footer-login-page {
    position: fixed;
    bottom: 20px;
    color: var(--M3-sys-light-on-surface, #191C1C);
    text-align: center;
    font-family: Inter;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;

    @include break($mobile-screen) {
        position: inherit;
        bottom: null;
    }
}


.footer-login-page-register {
    padding-bottom: 20px;
    text-align: center;
    font-size: 0.8rem;

    @include break($mobile-screen) {
        position: inherit;
        bottom: null;
        padding-bottom: 20px;
    }
}

.registerErrorMessage {
    color: red;
    margin-top: 20px;
    font-weight: 700;
}

.registerErrorMessage1 {
    color: red;
    margin-top: 20px;
    font-weight: 700;
    min-height: 550px;
}