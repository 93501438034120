.create-task-wrapper {
  padding: 25px 0;
  min-height: 500px;
  /*display: flex;*/
  background-color: #ffffff;

  .create-task-left-container {
    width: 100%;

    .section-item {
      margin: 0 20px 0 35px;

      &.hide {
        display: none;
      }

      &.remove-connet {
        &::after {
          display: none;
        }
      }
    }

    .section-item::after {
      content: '';
      display: block;
      border-left: 1.2px solid #000000;
      height: 6px;
      margin-left: 30px;
    }

    .section-item:last-child::after {
      display: none;
    }

    .create-task-field-activity {
      .field-activity-dropdowns {
        margin: 25px 0 53.5px 37.5px;
        display: flex;
        flex-wrap: wrap;

        .dropdowns {
          width: 335px;
          padding-right: 30px;
          padding-bottom: 30px;

          &:last-child {
            padding-bottom: 0;
          }

          .add-vehicle {
            padding: 10px;
            background-color: $task-create-bg;
            height: 51px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 20px;
            font-size: 14px;
            text-transform: none;
            color: $general-text-color;

            span {
              color: $page-bg-color;

              svg {
                width: 23px;

                circle {
                  stroke: grey;
                }

                path {
                  fill: grey;
                }
              }
            }
          }

          .css-1pcexqc-container {

            &.vehicle,
            &.equipment {
              .css-kj6f9i-menu {
                .css-11unzgr {
                  .css-1gl4k7y {
                    cursor: pointer;
                    padding: 0;
                  }
                }
              }
            }
          }

          .form-group {
            .error-message {
              position: initial;
            }
          }

          .css-1s9izoc {
            padding-bottom: 0;
            padding-top: 0;
          }

          .css-5ih5ya-group {
            margin-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            cursor: pointer;
          }

          @include break($mobile-screen) {
            width: 100%;
          }
        }
      }

      .light-box-container {
        position: relative;
        /*top: -55px;*/

        .light-box-heading {
          font-size: 16px;
          font-weight: 600;
        }

        .calendar-container {
          left: 0;
          top: 100px;
        }

        .light-box-content {
          .light-box-dropdown {
            padding-top: 8px;

            .form-group {
              label {
                padding-bottom: 0;

                &:first-child {
                  padding-top: 0;
                }
              }
            }

            .css-ksdk4d-menu {
              border-radius: 0;
              margin-bottom: 0px;
            }

            &:first-child {
              .css-11unzgr {
                max-height: 220px;
              }
            }

            .css-11unzgr {
              max-height: 170px;
            }
          }

          .light-box-dropdown:first-child {
            padding-top: 26px;
          }

          .light-box-dropdown.model-year {
            display: flex;
            justify-content: space-between;

            .light-box-dropdown-model {
              .form-group {
                width: 199px;
                min-width: 150px;
                height: 55px;
                text-align: left;
              }

              label {
                display: none;
              }

              margin-right: 15px;
            }

            .light-box-dropdown-year {
              min-width: 100px;
              width: 130px;
            }
          }
        }

        .create-asset-entity-error {
          margin-top: 15px;
        }

        .light-box-footer {
          display: flex;
          justify-content: center;
          margin: 20px 0 25px 0;

          button:first-of-type {
            margin-right: 20px;
          }
        }

        .error {
          color: $failure-color;
        }
      }
    }

    .create-task-time-recorded {
      display: flex;
      justify-content: space-evenly;
      position: relative;
      height: 335px;

      .create-task-time-recorded-item {
        flex-grow: 1;
        max-width: 360px;
        padding: 0 15px;

        .time-recorded-heading {
          position: absolute;
          top: 0;
          left: 0;

          @include break($tablet-screen) {
            position: relative;
          }
        }

        .calendar-wrapper {
          margin-top: 70px;
        }
      }

      .calendar {
        .calendar-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 278px;
          position: relative;
          transform: translateY(-25px);
          z-index: 1;

          .calendar-box {
            border: 1px solid $user-card-border;
            /*width: 450px;*/
            height: 140px;
            padding: 8px 10px;
            display: flex;
            flex-direction: column;
            position: relative;

            .calendar-top,
            .calendar-bottom {
              display: flex;
              justify-content: space-between;
              width: 100%;
            }

            .calendar-top {
              .startEnd {
                margin-bottom: 10px;
              }

              .startEndRight {
                float: right;
              }

              .editLink {
                color: $link-color;
                cursor: pointer;
              }

              .duration {
                text-align: center;
              }
            }

            .calendar-bottom {
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              bottom: 10px;
              width: 95%;
              position: absolute;
              padding-right: 5px;

              .endTimeDate {
                text-align: right;
              }

              .calendar-icon {
                font-size: 24px;
                display: flex;
                justify-content: center;
                align-self: flex-end;
                margin-bottom: 5px;
              }
            }

            h5 {
              font-size: 14px;
              font-weight: 600;
            }

            h2 {
              font-size: 18px;
              font-weight: 600;
            }
          }

          .calendar-container {
            top: 110px;
            left: 150px;

            .calendar-input {
              padding: 10px 5px;
            }

            .light-box {
              height: 75px;
              width: 362px;
              @include bdr-radius(2px);
              background-color: $light-box-bg;
              margin: 9px;
              display: flex;
              align-items: center;

              span {
                padding: 0 8px 0 15px;
              }

              span:first-child {
                padding: 0 15px;
              }

              .horizantalBar {
                box-sizing: border-box;
                height: 2px;
                width: 63px;
                border: 1px solid $seperator-bg;
              }

              .inputBox {
                box-sizing: border-box;
                height: 44px;
                width: 82px;
                border: 1px solid $input-border;
                @include bdr-radius(4px);
                background: $input-bg;
                display: flex;
                align-items: center;
                padding: 10px 8px;

                .inputText {
                  width: 30px;
                  height: 20px;
                  border: none;
                  display: block;
                  text-align: center;
                  color: $general-text-color;
                  font-size: 20px;
                  font-weight: 600;
                }

                .input:nth-child(2) {
                  margin-right: 15px;
                }

                .dateColon {
                  font-size: 20px;
                  font-weight: 600;
                  padding-bottom: 4px;
                }
              }
            }
          }
        }
      }

      .weather {
        .weather-container {
          justify-content: center;
          align-items: center;
          display: flex;
          height: 335px;

          .weather-wrapper {
            display: flex;

            .weather-icon {
              @include bdr-radius(50%);
              border: 1px solid grey;
              background-color: #fff;
              width: 40px;
              height: 40px;
              display: inline-block;
              margin: 0 8px;
              cursor: pointer;

              .icon {
                font-size: 25px;
                line-height: 40px;
                text-align: center;
                vertical-align: middle;
                display: inline-block;
                transform: translateX(25%);
              }

              p {
                font-size: 10px;
                text-align: center;
                color: #aeaeae;
                width: 40px;
                margin-top: 2px;
              }
            }

            .weather-icon:hover {
              background-color: #abe2fb;
            }

            .activeClass {
              background-color: #abe2fb;
              border: 1px solid #2a3c63;

              p {
                color: #000;
              }
            }
          }
        }
      }

      .wind {
        .wind-container {
          height: 335px;
          display: flex;
          justify-content: center;
          align-items: center;

          @include break($laptop-screen) {
            height: auto;
            margin: 70px 0;
          }
        }
      }

      @include break($laptop-screen) {
        flex-wrap: wrap;
        height: auto;

        div.vertical-line:nth-child(4) {
          display: none;
        }
      }
    }

    .create-task-pesticide-journal {
      height: auto;
      display: flex;
      flex-direction: column;
      min-height: 230px;

      label {
        font-size: 12px;
        font-weight: 600;
      }

      .left-wrapper {
        display: flex;
        flex-direction: column;
        max-width: 360px;

        .pesticide-left-form {
          margin: 13px 25px 91px 25px;
          max-width: 315px;

          div.form-group:not(:first-child) {
            margin-top: 10px;
          }

          .amountInput {
            display: inline-flex;

            .form-group {
              width: 167px;

              .form-control {
                width: 100%;
              }
            }
          }
        }
      }

      .middle-wrapper {
        margin: 85px 25px 91px 25px;
        max-width: 315px;

        div.form-group:not(:first-child) {
          margin-top: 10px;
        }
      }

      .right-wrapper {
        margin: 85px 25px 91px 25px;

        .effect-slider {
          margin: 7px 0 35px;
        }

        .harvest-date-align {
          padding-top: 10px;
          width: 300px;
        }

        .measureInput {
          padding-top: 10px;
          width: 290px;
        }
      }

      .form-group .form-control {
        text-align: left;
      }

      .dynamic-wrapper {
        padding: 20px 30px 25px 30px;
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .form-group {
          max-width: 325px;
          padding-bottom: 15px !important;

          @include break($mobile-screen) {
            min-width: 100%;
          }
        }

        .multiple-pesticide-section {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: space-evenly;

          .multiple-pesticide-sub-section {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            /*width: 75%;*/

            .form-group {
              @include break($laptop-screen) {
                max-width: 270px;
                width: 270px;
              }

              @include break($tablet-screen) {
                max-width: 200px;
                width: 200px;
              }

              @include break($mobile-screen) {
                max-width: 140px;
                min-width: 140px;
              }
            }

            .add-icon {
              color: $card-new-icon;
            }

            .delete-icon {
              @include bdr-radius(50%);
              background-color: $user-trash-bg;
              color: $card-plus-icon;
              font-size: 23px;
              vertical-align: middle;
              padding: 10px;
              font-weight: 300;
              width: 35px;
              height: 35px;
              margin-right: 10px;

              svg {
                transform: translate(-18%, -35%);
                fill: $page-bg-color;
              }
            }

            .pesticide-section-controls {
              width: 130px;
              max-width: 325px;
              display: flex;
              margin-top: 30px;

              div {
                cursor: pointer;
              }

              @include break($laptop-screen) {
                width: 100px;
                max-width: 140px;
              }

              @include break($tablet-screen) {
                width: 50px;
                max-width: 70px;
              }
            }
          }
        }

        .pestsubdata .css-bg1rzq-control,
        .pestsubdata .css-1szy77t-control {
          min-height: 55px;
          height: auto;
        }

        .css-1alnv5e:hover {
          background-color: #90ee90;
        }

        .slider {
          margin-left: 10px;
          padding-bottom: 25px;

          @include break($mobile-screen) {
            margin-left: 20px;
            padding-bottom: 35px;
          }
        }

        .fvhd {
          margin-left: 8px;

          @include break($mobile-screen) {
            margin-left: 0;
          }
        }

        .select-box-common {
          @include break($mobile-screen) {
            margin-top: 0;
          }
        }

        .calendar-harvest-date-wrapper {
          position: relative;
          width: 325px;

          .calendar-container {
            top: 0;

            .calendar-input {
              padding: 10px 5px;
            }
          }

          @include break($mobile-screen) {
            width: 99%;
          }
        }
      }

      .dynamic-wrapper::after {
        content: '';
        flex: 0 0 31%;
      }

      .dynamic-wrapper1 {
        padding: 20px 30px 25px 30px;
        position: relative;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
        column-gap: 2rem;
        /*flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;*/

        .form-group {
          max-width: 325px;
          padding-bottom: 15px !important;

          @include break($mobile-screen) {
            min-width: 100%;
          }
        }

        .multiple-pesticide-section {
          display: flex;
          flex-direction: column;
          width: 100%;
          justify-content: space-evenly;

          .multiple-pesticide-sub-section {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;

            .form-group {
              @include break($laptop-screen) {
                max-width: 270px;
                width: 270px;
              }

              @include break($tablet-screen) {
                max-width: 200px;
                width: 200px;
              }

              @include break($mobile-screen) {
                max-width: 140px;
                min-width: 140px;
              }
            }

            .add-icon {
              color: $card-new-icon;
            }

            .delete-icon {
              @include bdr-radius(50%);
              background-color: $user-trash-bg;
              color: $card-plus-icon;
              font-size: 23px;
              vertical-align: middle;
              padding: 10px;
              font-weight: 300;
              width: 35px;
              height: 35px;
              margin-right: 10px;

              svg {
                transform: translate(-18%, -35%);
                fill: $page-bg-color;
              }
            }

            .pesticide-section-controls {
              width: 325px;
              max-width: 325px;
              display: flex;
              margin-top: 30px;

              div {
                cursor: pointer;
              }

              @include break($laptop-screen) {
                width: 100px;
                max-width: 140px;
              }

              @include break($tablet-screen) {
                width: 50px;
                max-width: 70px;
              }
            }
          }
        }

        .pestsubdata .css-bg1rzq-control,
        .pestsubdata .css-1szy77t-control {
          min-height: 55px;
          height: auto;
        }

        .css-1alnv5e:hover {
          background-color: #90ee90;
        }

        .slider {
          margin-left: 10px;
          padding-bottom: 25px;

          @include break($mobile-screen) {
            margin-left: 20px;
            padding-bottom: 35px;
          }
        }

        .fvhd {
          margin-left: 8px;

          @include break($mobile-screen) {
            margin-left: 0;
          }
        }

        .select-box-common {
          @include break($mobile-screen) {
            margin-top: 0;
          }
        }

        .calendar-harvest-date-wrapper {
          position: relative;
          width: 325px;

          .calendar-container {
            top: 0;

            .calendar-input {
              padding: 10px 5px;
            }
          }

          @include break($mobile-screen) {
            width: 99%;
          }
        }
      }

      .dynamic-wrapper1::after {
        content: '';
        flex: 0 0 31%;
      }
    }

    .create-task-assignee-notes {
      height: 531px;
      display: flex;

      .assignee-notes-left-wrapper {
        min-width: 360px;

        .assignee-wrapper {
          padding: 22px 34px 0 34px;

          .form-control {
            width: 315px;
            height: 55px;
            text-align: left;
          }
        }
      }

      .assignee-notes-right-wrapper {
        display: flex;
        flex-direction: column;
        padding: 94px 30px 30px 34px;
        width: 100%;

        .textarea-placeholder-size {
          height: 287px;
          width: 100%;
          text-align: left;
          resize: none;
        }
      }

      label {
        font-size: 12px;
        font-weight: 600;
      }
    }

    .create-task-field-activity,
    .create-task-time-recorded,
    .create-task-assignee-notes,
    .create-task-pesticide-journal {
      border: 1px solid $user-card-border;
      @include bdr-radius(2px);
      border-left: 6px solid $user-card-border;
      background-color: $page-bg-color;
    }

    .create-task-field-activity:hover,
    .create-task-time-recorded:hover,
    .create-task-assignee-notes:hover,
    .create-task-pesticide-journal:hover {
      border-left: 6px solid $task-card-border;
    }

    .field-activity-heading,
    .time-recorded-heading,
    .assignee-notes-heading,
    .pesticide-journal-heading {
      display: flex;
      align-items: center;

      span:first-child {
        margin: 33px 18px 33px 34px;
      }

      span:nth-child(2) {
        font-size: 20px;
        font-weight: 600;
      }

      .black-dot {
        @include bdr-radius(50%);
        background-color: #000;
        font-size: 0px;
        vertical-align: middle;
        padding: 3.2px;
      }
    }

    .vertical-line {
      border: 0.5px solid #eeeeee;
      height: auto;
    }

    @include break($tablet-screen) {
      .create-task-time-recorded {
        height: auto;
        flex-direction: column;

        .calendar {
          .calendar-wrapper {
            height: auto;
            margin-top: 50px;
            margin-left: 34px;
            justify-content: flex-start;
          }
        }

        .weather {
          .weather-container {
            height: auto;
            justify-content: flex-start;

            .weather-wrapper {
              margin: 30px 34px 50px;
            }
          }
        }

        .wind {
          .wind-container {
            height: auto;
            justify-content: flex-start;
            margin: 0;

            .slider {
              margin: 30px 34px 80px;
            }
          }
        }

        div:nth-child(4) {
          display: block;
        }
      }

      .create-task-assignee-notes {
        height: auto;
        flex-direction: column;

        .assignee-notes-left-wrapper {
          margin-bottom: 30px;
        }

        .assignee-notes-right-wrapper {
          padding-top: 20px;

          .textarea-placeholder-size {
            height: 287px;
            width: 100%;
          }
        }
      }

      .create-task-pesticide-journal {
        height: auto;
        flex-direction: column;

        .left-wrapper {
          .pesticide-left-form {
            margin: 13px 25px 30px 30px;
          }
        }

        .middle-wrapper {
          margin: 30px 25px 30px 30px;
        }

        .right-wrapper {
          margin: 30px 25px 30px 30px;
        }
      }

      .vertical-line {
        margin: 0px 20px;
      }
    }

    @include break($tablet-screen) {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .slider {
    width: 315px;

    .rc-slider-rail {
      background-color: #fff;
      border: 1px solid #cccccc;
      height: 15px;
      @include bdr-radius(3px);
    }

    .rc-slider-handle {
      height: 25px;
      width: 25px;
      border: 1px solid #000000;
      @include bdr-radius(4px);
      background: #fafafa;
    }

    .rc-slider-handle::after {
      content: '';
      border: 1px solid #cccccc;
      border-top: transparent;
      border-bottom: transparent;
      margin: 4px 7px;
      padding: 5px 0;
      display: block;
      height: 6px;
    }

    .rc-slider-track {
      height: 15px;
    }

    .rc-slider-step {
      .rc-slider-dot {
        display: none;
      }
    }

    .rc-slider-mark {
      span {
        margin-top: 5px;
      }
    }

    .slider-heading {
      display: flex;
      max-height: 30px;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 12px;
        font-weight: 600;

        .selected-value {
          font-family: 'PT Serif', serif;
          font-size: 26px;
          font-weight: bold;
        }

        .units {
          padding-left: 2px;
          font-weight: 300;
        }

        .measure {
          margin-right: 10px;
        }
      }

      p:last-child {
        display: flex;
        align-items: center;
      }
    }
  }

  .create-task-delete {
    width: 8%;
    margin: 15px 5px;
    display: flex;
    justify-content: center;

    .delete-icon {
      button {
        background: $user-trash-bg;
        float: right;
        height: 43px;
        width: 43px;
        border-radius: 50%;
        margin: -5px 0 0 10px;

        svg {
          fill: $btn-primary-color;
          margin: 1px 0 0px -2px;
        }
      }
    }

    @include break($tablet-screen) {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: auto;
      margin: 10px 20px 10px;
    }

    @include break($mobile-screen) {
      margin-bottom: 10px;
    }
  }

  .task-loader {
    position: fixed;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @include break($tablet-screen) {
    flex-direction: column-reverse;
  }

  @include break($mobile-screen) {
    .create-task-left-container {
      .section-item {
        margin: 0 20px 0 20px;
      }
    }
  }
}

.pesticide-agree {
  p {
    padding: 10px 0;
  }

  h2 {
    padding-right: 10px;
  }
}

#pesticide-agree-check {
  &+label {
    padding-right: 0;

    span {
      font-size: 12px;
      color: $link-color;
    }
  }
}

.task-sumbit {
  padding: 20px 20px 20px 35px;

  #pesticide-agree-check {
    &+label {
      padding-right: 2rem;
    }
  }

  .complete-check,
  .pesticide-agree {
    &>div {
      input {
        right: 0.1875rem;
        left: unset;
      }

      label {
        span {
          font-size: 14px;
        }

        &::before {
          left: calc(100% - 25px);
        }

        svg {
          left: unset;
          right: 0.4rem;
        }
      }
    }
  }

  .complete-check {
    padding: 20px 0;
    position: relative;

    &>div {
      float: right;
    }

    &+.task-save-complete {
      padding: 0;
    }
  }

  .task-save-complete {
    padding: 50px 0 10px;

    button {
      float: right;

      &:first-child {
        margin-left: 32px;
      }
    }
  }

  .task-message {
    text-align: right;
    margin-top: 5px;
    padding: 10px 0;
  }

  .success {
    color: $success-color;
  }

  .error {
    color: $failure-color;
  }
}

.task-pesticide-check {
  border-bottom: 1px solid #eee;
  padding: 0 0 22px;

  .pesticide-agree {
    float: right;
    width: auto;
  }
}

.error-message {
  float: left;
}

.task-pesticide-check-agreement {
  float: right;
  width: auto;
  padding-right: 10px;
}

.task-pesticide-check-agreement-error {
  padding: 20px 30px 0px 0px;
  float: right;
}

.multiple-pesticide-sub-section-bottom {
  padding-bottom: 20px;

  div {
    div {
      div {
        div {
          padding-top: 4px;
        }
      }
    }
  }
}

.pesticideamount {
  padding-top: 20px !important;
}

.datePickerTextbox {
  margin-bottom: 5px;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: 'Inter';
}

.margin-top-water-journal {
  margin-top: 35px;
}

.water-journal {
  padding: 20px 30px 25px 30px;

  .water-journal__yes-no {
    float: left;
  }

  .bold-font {
    font-weight: 600;
  }
}

.water-journal-padding {
  padding: 20px 30px 25px 0px;

  .water-journal__yes-no {
    float: left;
  }

  .bold-font {
    font-weight: 600;
    font-size: 0.75rem;
  }
}

.water-journal-padding-new {
  padding: 6px 30px 25px 0px;

  .water-journal__yes-no {
    float: left;
  }

  .bold-font {
    font-weight: 600;
    font-size: 0.75rem;
  }
}

.padding-bottom-zero {
  padding-bottom: 0px !important;
}

.div-max-width {
  max-width: 205px;
}