.map-container {
    @extend .full-width;
    padding-bottom: 50px;
}

.validation-info {
    @extend .full-width;
    padding: 20px 0 20px 15px;

    ul {
        display: flex;
    }

    li {
        flex: 1;
        padding: 0 15px 0 0;
    }

    .dv-lbl {
        clear: both;
        float: left;
        padding: 15px 0 0;
    }

    .dv-value {
        float: left;
        clear: both;
        padding: 5px 0 0;
    }
}

.dv-lbl {
    padding: 0 0 10px;
}

.option-selection {

    [class^="styled-components__FlexGridWrapper"],
    [class*=" styled-components__FlexGridWrapper"] {
        overflow: visible;
    }
}

.loader-animation.validator-loader {
    margin: 0 auto;
    display: block;
}

.filellsit {
    background: $tbl-bg;
    border: 1px solid $tbl-bdr;
    border-collapse: collapse;
    width: 100%;

    td,
    th {
        border: 1px solid $tbl-bdr;
        padding: 10px;
    }

    th {
        background: $tbl-th-bg;
    }
}

.map-data {
    &.hide-map {
        visibility: hidden;
    }

    &.show-map {
        visibility: visible;
    }
}