.fertilization-blue-header-mui {
  width: 100%;
  background-color: transparent;
  padding: 15px 12px 8px 12px;
}

.fertilization-blue-mineral-header-mui {
  width: 100%;
  background-color: transparent;
  padding: 0px 12px 15px 12px;
}

.padding-bottom-alignment-mui {
  padding: 0px 20px 15px 20px;
}

.padding-top-mui {
  padding-top: 30px;
}