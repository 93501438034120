.headerBorderTop {
  border-top: 1px solid #eee;
  padding: 30px 20px;
}

.header {
  background: $header-bg;
  min-height: 80px;
  padding: 0 30px;

  .headerBorder {
    border-left: 2px solid #eee;
    padding-left: 20px;
  }

  .profile-link {
    color: $general-text-color;
    float: right;
    padding-top: 28px;

    .display-name {
      float: left;
      font-size: 14px;
      max-width: 150px;
      padding: 8px 12px 0 0;
    }

    .profile-img {
      float: right;

      .spinner {
        margin: 0;
      }
    }
  }

  p {
    float: left;
  }

  @include break($mobile-screen) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: $header-bg;
    min-height: 90px;
    padding: 0 25px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    z-index: 9;
    background-color: var(--M3-sys-light-surface, #F7FAF9);

    p {
      margin: 0 0 0 35px;
    }

    .profile-link {
      padding-top: 45px;
      padding-right: 4px;

      .display-name {
        display: none;
      }
    }
  }
}

.header-new {
  background: $header-bg;
  min-height: 80px;
  padding: 0 30px;

  .headerBorder {
    border-left: 2px solid #eee;
    padding-left: 20px;
  }

  .profile-link {
    color: $general-text-color;
    float: right;
    padding-top: 28px;

    .display-name {
      float: left;
      font-size: 14px;
      max-width: 150px;
      padding: 8px 12px 0 0;
    }

    .profile-img {
      float: right;

      .spinner {
        margin: 0;
      }
    }
  }

  p {
    float: left;
  }

  @include break($mobile-screen) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: $header-bg;
    min-height: 90px;
    padding: 0 25px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    z-index: 9;
    background-color: var(--M3-sys-light-surface, #F7FAF9);

    p {
      margin: 0 0 0 35px;
    }

    .profile-link {
      padding-top: 45px;
      padding-right: 4px;

      .display-name {
        display: none;
      }
    }
  }
}

.breadcrumb {
  float: left;
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 500;

  span {
    color: $general-text-color;
    border-left: 2px solid $breadcrumb-bdr-color;
    float: left;
    padding: 33px 0 12px 18px;

    &:first-child {
      border: none;
      padding-left: 0;
    }
  }

  a {
    color: $breadcrumb-color;
    float: left;
    font-size: 12px;
    padding-right: 18px;
    padding: 38px 18px 12px 0;
  }

  .header-title-name {
    color: var(--M3-sys-light-on-surface, #191C1C);
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  @include break($mobile-screen) {
    font-size: 12px;
    padding-left: 10px;
    padding-top: 28px;

    a {
      font-size: 10px;
      padding: 25px 10px 10px 0;
    }

    span {
      height: auto;
      padding: 20px 0 10px 10px;
    }

    .header-title-name {
      max-width: 100px;
    }
  }

  @include break($laptop-screen) {
    .header-title-name {
      max-width: 300px;
    }
  }
}

.breadcrumb-new {
  float: left;
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 500;

  span {
    color: $general-text-color;
    float: left;
    padding: 33px 0 12px 18px;

    &:first-child {
      border: none;
      padding-left: 0;
    }
  }

  a {
    color: $breadcrumb-color;
    float: left;
    padding-right: 18px;
    padding: 32px 0px 12px 0;
    color: var(--M3-sys-light-on-surface, #191C1C);
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  .header-title-name {
    color: var(--M3-sys-light-on-surface, #191C1C);
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }

  @include break($mobile-screen) {
    font-size: 12px;
    padding-left: 10px;
    padding-top: 28px;

    a {
      font-size: 10px;
      padding: 25px 10px 10px 0;
    }

    span {
      height: auto;
      padding: 20px 0 10px 10px;
    }

    .header-title-name {
      max-width: 100px;
    }
  }

  @include break($laptop-screen) {
    .header-title-name {
      max-width: 300px;
    }
  }
}

.farm-selection-bar {
  background: $header-farm-bg;
  padding: 10px 48px 10px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: $header-farm-zindex;

  .icon-farm {
    color: $farm-icon-color;
    float: right;
    font-size: 1.62rem;
    margin-right: 0.5rem;
  }

  .farm-header-select {
    float: right;

    .css-bg1rzq-control,
    .css-1szy77t-control {
      background: none;
      border: none;
      box-shadow: none;
      cursor: pointer;
      outline: none;
      padding: 0;
      width: 115px;
      min-height: 0;
    }

    .css-1hwfws3 {
      padding: 0;
    }

    .css-1g6gooi {
      padding: 0;
    }

    .css-dvua67-singleValue {
      color: #000;
      font-weight: $font-semi-bold;
      font-size: 1rem;
      max-width: none;
      margin-left: 3px;
      width: 100px;
    }

    .css-1thkkgx-indicatorContainer,
    .css-16pqwjk-indicatorContainer {
      padding: 0;
      color: $terms-heading-color;
      display: initial;

      span {
        font-size: 12px;
      }
    }

    .css-1wy0on6 {
      background: transparent;
      margin-top: 0;
      width: 15px;
    }

    .css-kj6f9i-menu {
      background: $dropdown-bg;
      border: 1px solid $dropdown-bdr;
      border-top: 0;
      border-radius: 2px;
      box-shadow: none;
      margin-top: 0;
      right: 0;
      min-width: 200px;
    }

    .css-11unzgr {
      padding-top: 0;
    }

    .css-xo7z33-option,
    .css-fk865s-option,
    .css-dpec0i-option {
      color: $general-text-color;
      cursor: pointer;
      padding: 15px 20px;
      position: relative;
      word-break: break-all;

      &::before {
        content: '';
        position: absolute;
        height: 1px;
        background: $dropdown-item-bdr;
        width: 90%;
        bottom: 0;
        z-index: 1;
        left: 5%;
      }
    }

    .css-xo7z33-option {
      background: $dropdown-item-active-bg;
    }

    .css-fk865s-option {
      background: $dropdown-item-bg;
    }
  }

  .farm-header-selected-text {
    color: $terms-heading-color;
    clear: both;
    font-size: 16px;
    float: left;
    margin-left: 25px;
    padding-bottom: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 80%;
    cursor: default;
  }



  @include break($navbar-break) {
    padding: 10px 20px;
  }
}

.btn-nav-menu {
  border: 0;
  background: transparent;
  display: none;
  float: left;
  margin: 10px 10px;
  padding: 0;
  position: relative;
  cursor: pointer;
  height: 48px;
  width: 48px;

  /* Center the background image and set its size */
  span {
    background: url('openMenuIcon.svg') center center no-repeat;
  }

  &:active {
    background-color: #F2F4F4 !important;
    border-radius: 100px !important;
  }

  &.menu-open {
    background: url('closeMenuIcon.svg') center center no-repeat;
  }

  @include break($navbar-break) {
    display: inline-block;
    position: fixed;
    z-index: 10;
    top: 38px;
    left: 5px;
    background: url('openMenuIcon.svg') center center no-repeat;

    &:active {
      background-color: #F2F4F4 !important;
      border-radius: 100px !important;
    }
  }
}
