.mui-tabs {}

.MuiGrid-root {
  justify-content: flex-start !important;
}

.paddingBottomList {
  padding-bottom: 25px;
}

.mui-tab-label {
  text-transform: none !important;
  padding: 0px 30px !important;
  font-weight: 500 !important;
}

.MuiTabs-indicator {
  background: #00696D !important;
  width: 10px;
}

.Mui-selected {
  color: #00696D !important;
}

.MuiChip-colorPrimary {
  color: #00696D !important;
  font-weight: 500 !important;
  border-color: #6F7979 !important;
  vertical-align: middle !important;
}

.MuiGrid-container {
  vertical-align: middle !important;
}

.grid-padding-top {
  margin-top: 8px !important;
}

.grid-padding-top-small {
  margin-top: 2px !important;
}

.grid-padding {
  padding: 10px !important;
}

.grid-padding-reset {
  padding: 10px 2px !important;
}

.grid-padding-dot {
  padding-left: 30px !important;
  padding-right: 10px !important;
  padding-top: 3px;
}

.grid-padding-dot-mobile {
  padding-right: 10px !important;
  padding-top: 3px;
}

.grid-padding-dot-mf {
  padding-left: 30px !important;
  padding-right: 10px !important;
  padding-top: 4px;
}

.grid-no-padding {
  padding: 0px !important;
}

.text-with-padding {
  padding-bottom: 25px !important;
  //padding-right: 25px !important;
}

.text-with-padding-small {
  padding-bottom: 18px !important;
  padding-right: 25px !important;
}

.list-grid-box {
  background-color: #f9f9f9;
  border-radius: 8px;
}

.list-grid-with-padding {
  padding-bottom: 15px !important;
}

.customTabDiv {
  div.MuiBox-root {
    padding-top: 5px !important;
  }
}

.customFieldTabDiv {
  div.MuiBox-root {
    padding-left: 0px !important;
    padding-right: 10px !important;
  }
}

@media (max-width: 600px) {
  .customFieldTabDiv {
    div.MuiBox-root {
      padding-left: 10px !important;
      padding-right: 10px !important;
      padding: 15px 0px;
    }
  }
}

.customFertilizerTabDiv {
  div.MuiBox-root {
    padding: 0px !important;
  }
}

.customGrassTabDiv {
  div.MuiBox-root {
    padding: 10px !important;
  }
}

.field-list-font-title {
  font-family: 'Inter' !important;
  font-size: 16px !important;
  color: #191C1C !important;
}

.field-list-font-subtitle {
  font-family: 'Inter' !important;
  font-size: 14px !important;
  color: #3F4949 !important;
}

.field-list-font-subtitleheading {
  font-family: 'Inter' !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #3F4949 !important;
}

.noMargin-noPadding {
  padding: 0px !important;
  margin: 0px !important;
}

.fixed-bottom-mui {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  /* Add your background color if needed */
  padding: 10px;
  /* Add padding or adjust as needed */
  /* Add any other styles as needed */
}

.fixed-button-fertilization {
  background-color: #ffffff;
  border-top: 1px solid #e7e7e7;
  /*padding: 10px 0px 20px 0px;*/
  position: sticky;
  bottom: -1px;
  /*height: 90px;*/
  width: 100%;
  z-index: 1;
  height: 62px
}

.padding-null {
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}

.react-datepicker-popper {
  z-index: 2;
}

.MuiTab-root {
  color: #3F4949 !important;
}

.MuiTab-root.Mui-selected {
  color: #00696D !important;
}