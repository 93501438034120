.manure-storage-form {
  .form-group {
    float: unset;

    .error-message {
      float: left;
    }
  }
}

.manure-form__name {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  &.create {
    flex-direction: row;
  }

  .form-group {
    max-width: 458px;
    width: 60%;
  }

  button {
    align-self: flex-start;
  }

  @include break($mobile-screen) {
    display: unset;

    .form-group {
      width: 100%;
      max-width: unset;
    }

    button {
      display: flex;
      margin-left: auto;
    }
  }
}

.manure-storage__fields {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  grid-gap: 1.5rem;
}

.manure-storage-calc__heading {
  display: flex;
  justify-content: space-between;

  button {
    i {
      float: right;
      margin: 2px 0 0 10px;
    }
  }
}

.manure-storage-calc__data {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  row-gap: 2rem;
  padding-top: 2.5rem;

  &.--edit {
    column-gap: 1rem;
  }

  &.--list-content {
    padding: 2rem 0;
  }
}

.manure-storage__calculated-value {
  border-right: 1px solid $common-bdr-color;
  margin-right: 1.5rem;

  &:last-child {
    border: none;
    margin-right: 0;
  }
}

.manure-storage_calc-label {
  padding-bottom: 1rem;
}

.manure-storage__submit {
  display: flex;
  padding: 1rem 0 0;

  button {
    margin-left: auto;
  }
}

.padding-bottom-low {
  padding-bottom: 10px;
}

.manure-list__Head {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  p {
    max-width: 80%;
  }

  a {
    div {
      color: $positive;
    }
  }

  @include break($mobile-screen) {
    display: unset;

    button {
      display: flex;
      margin-left: auto;
    }

    p {
      width: 100%;
    }
  }
}

.manure-item_header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .manure-item_link {
    a {
      margin: 0 1rem;

      i {
        margin: 2px 0 0 10px;
        float: right;
      }
    }
  }
}

.fertilization-plan-list-content-item_header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .fertilization-plan-list-content-item_link {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.manure-change-warning {

  &.confirm-box-stye [class^='confirm-dialog-wrapper'],
  &.confirm-box-stye [class*=' confirm-dialog-wrapper'] {
    max-width: 420px;

    button {
      min-width: 20%;
    }
  }
}

.manure-error {
  text-align: right;
}

.sample-uploaded-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0 50px;

  .field-info-text {
    padding: 8px 0 17px;
  }
}

.soil-info-text {
  padding-bottom: 50px;

  span {
    width: 50%;
    display: flex;

    @include break($navbar-break) {
      width: 100%;
    }
  }
}

.sample-list-container {
  display: flex;
  border: 1px solid $common-bdr-color;
  border-left-width: 5px;
  border-radius: 2px;
  margin-top: 40px;

  &:last-child {
    margin-bottom: 40px;
  }

  .soil-info-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
    column-gap: 6px;
    max-width: 700px;
    width: 65%;

    .soil-info-list__item {
      border-bottom: 1px solid #eeeeee;
      height: 47px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fafafa;
      padding: 0 25px;

      &:last-child,
      &:nth-last-child(2) {
        border-bottom: none;
      }
    }
  }

  .field-update {
    width: 45%;
    padding: 25px;

    .sample-no {
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      span:first-child {
        padding-right: 10px;
      }
    }

    .sample-date {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .field-options {
      max-width: 255px;
      padding: 80px 0 0 20px;
    }
  }
}

.soil-sample-details {
  position: relative;
  padding-bottom: 80px;

  .sample-update-done {
    position: fixed;
    bottom: 20vw;
    right: 40px;
    z-index: $done-zindex;
  }
}

.fertilizerplan-list__Head {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  p {
    max-width: 80%;
  }

  a {
    div {
      color: $positive;
    }
  }

  @include break($mobile-screen) {
    display: unset;

    button {
      display: flex;
      margin-left: auto;
    }

    p {
      width: 100%;
    }
  }
}

.fertilization-grey-header {
  width: 100%;
  background-color: #f6f6f6;
}

.fertilization-blue-header {
  width: 100%;
  background-color: #e8f5fa;
  padding: 1px;
}

.fertilization-grey-header {
  width: 100%;
  background-color: #f5f5f5;
  padding: 1px;
}

.fertilization-grey-header-mui {
  width: 100%;
  padding: 1px;
}

.fertilization-white-header {
  width: 100%;
  background-color: #ffffff;
}

.fertilization-header-text {
  padding: 20px 0px 20px 30px;
}

.fertilization-content {
  margin: 0px 15px 15px 15px;
}

.fertilization-box {
  margin: 0px 12px 15px 15px;
  width: 31%;
  display: inline-flex;
  background-color: #ffffff;
}

.fertilization-create {
  vertical-align: middle;
  text-align: center;
  padding: 80px;
}

.fertilization-create-button {
  padding-top: 30px;
}

.close-modal {
  margin-right: 15px;
  float: right;
}

.add-fertilizer {
  cursor: pointer;
  margin-top: -220px;

  .add-icon {
    display: inline-block;
    vertical-align: middle;
    color: $card-new-icon;

    svg {
      width: 23px;
      height: 23px;
    }
  }

  a {
    color: $general-text-color;
  }
}

.add-mineral-fertilizer {
  cursor: pointer;
  float: right;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 25px;

  .add-icon {
    display: inline-block;
    vertical-align: middle;
    color: $card-new-icon;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  a {
    color: $general-text-color;
  }
}

.add-mineral-fertilizer-mui {
  cursor: pointer;
  float: left;
  padding-top: 2px;
  padding-bottom: 20px;
  padding-right: 25px;
}

.pad-right {
  padding-right: 5px;
}

.fertilization-header {
  font-size: 20px;
}

.fertilizer-form-style {
  display: block;
  margin-top: 30px;
  padding-left: 2%;
  padding-right: 2%;
}

.fertilizer-form-style-button {
  display: block;
  margin-top: 40px;
  margin-bottom: 30px;
  padding-left: 2%;
  padding-right: 2%;
}

.button-style {
  float: right;
  padding-top: 20px;
}

.button-style-modal {
  text-align: center;
  padding-top: 300px;
}

.button-style-yes {
  float: right;
  padding-top: 20px;
  padding-right: 30px;
}

.padding-right-heavy {
  padding-right: 20px;
}

.button-style-cancel {
  float: left;
  padding-top: 20px;
  padding-left: 50px;
}

.button-style-cutdate {
  float: left;
  padding-top: 20px;
}

.button-style-padding-right {
  padding-right: 40px;
}

.button-style-cancel-taskconfirm {
  float: left;
  padding-top: 20px;
  padding-left: 20px;
}

.button-style-yes-taskconfirm {
  float: right;
  padding-top: 20px;
  padding-right: 20px;
}

.manure-grid-list {
  display: grid;
  grid-template-columns: 30% 2px 30% 2px 40%;
  width: 100%;

  .grid-item-center {
    width: 100%;
    text-align: center;
  }

  .grid-item-right {
    width: 100%;
    text-align: right;
  }

  .grid-item-left {
    width: 100%;
    text-align: left;
  }

  .grid-completed-left {
    text-align: left !important;
  }

  .grid-box-flex-right {
    display: flex;
    float: right;
  }

  .grid-box-flex-left {
    display: flex;
    float: left;
    padding-left: 10px;
  }

  .grid-box-flex-center {
    display: flex;
  }

  .flex-without-border {
    padding-right: 25%;
    //padding-left: 5%;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .flex-border {
    //border-right: 2px solid $common-bdr-color;
    //padding-right: 5%;
    padding-left: 20%;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .flex-corner {
    text-align: center;
    align-self: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .flex-div-right {
    float: right;
    width: 100%;
  }

  .flex-div-left {
    float: left;
    width: 100%;
  }

  .flex-div-inherit {
    float: inherit;
  }

  .flex-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .flex-width {
    align-self: flex-end;
    width: 22px;
    bottom: 0;
  }

  .flex-bar-green {
    min-height: 135px;
    width: 100%;
    background-color: #06842e;
  }

  .flex-bar-blue {
    min-height: 180px;
    width: 100%;
    background-color: #bce2f2;
  }

  .inline-flex-border {
    height: 80%;
    width: 2px;
    border-left: 2px solid $common-bdr-color;
    align-self: center;
  }
}

.text-heading-font {
  font-size: 16px;
  font-weight: $font-light;
}

.mineral-fertilizer-box {
  width: 100%;
  height: 220px;
  margin: 20px;
}

.mineral-fertilizer-text-right {
  float: right;
}

.floating-button-right {
  float: right;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 1000;
}

.mineral-fertilizer-text-left {
  text-align: center;
}

.mineral-fertilizer-text-bold {
  font-weight: 600;
}

.mineral-fertilizer-grid {
  display: grid;
}

.mineral-fertilizer-edit-border {
  border: 1px solid #333333;
  padding: 8px;
  border-radius: 16px;
  cursor: pointer;
}

.mineral-fertilizer-grid-height {
  height: 50%;
}

.mineral-fertilizer-grid-div {
  text-align: center;
  align-self: center;
}

.padding-top-size {
  padding-top: 15px;
}

.text-line-through {
  text-decoration: line-through;
}

.fertilizer-plan-padding {
  padding: 30px 30px 0px 30px;
}

.fertilizer-plan-list-padding {
  padding: 20px 25px 0px 25px;
}

.fertilizer-plan-list-content-background {
  background-color: #fafafa;
}

.fertilizer-plan-list-content-padding {
  padding: 0px 8px 8px 8px;
}

.fertilizer-plan-list-content {
  padding: 15px;
  background-color: #f5f5f5;
}

.border-left-green {
  border-left: 5px solid #06842e;
}

.border-left-grey {
  border-left: 5px solid #aeaeae;
}

.fertilizer-plan-header-calc__data {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  row-gap: 2rem;
  padding-top: 2rem;

  &.--edit {
    column-gap: 1rem;
  }
}

.fertilizer-plan-list-header-calc__data {
  display: grid;
  grid-template-columns: 18% 82%;
  row-gap: 2rem;
  padding-top: 0.4rem;

  &.--edit {
    column-gap: 1rem;
  }
}

.fertilizer-plan-header__calculated-value {
  margin-right: 1.5rem;

  &:last-child {
    border: none;
    margin-right: 0;
  }
}

.fertilizer-plan-header_calc-label {
  padding-bottom: 1.2rem;
}

.margin-right-padding {
  margin-right: 10px;
}

.padding-top-alignment {
  padding-top: 10px;
}

.padding-bottom-alignment {
  padding-bottom: 30px;
}

.fertilization-plan-content {
  display: grid;
  grid-template-columns: 48% 52%;
  width: 100%;

  .fertilization-plan-grid {
    width: 100%;
    padding: 20px;
  }

  .text-capitalize {
    text-transform: capitalize;
  }

  .fertilization-plan-grid-blue-border {
    border-right: 1px solid #bce2f2;
    width: 100%;
    padding: 20px;
  }

  .fertilization-plan-grid-blue-border-mui {
    border-right: 1px solid #BEC8C9;
    width: 100%;
  }

  .fertilization-plan-grid-grey-border {
    border-right: 1px solid #e5e5e5;
    width: 100%;
    padding: 20px;
  }

  .--grid-layout {
    display: grid;
    grid-template-columns: (38% 22% 22% 20%);
    width: 100%;
    justify-content: space-between;
  }

  .--grid-layout-menu {
    display: grid;
    grid-template-columns: (42% 25% 10% 23%);
    width: 100%;
    justify-content: space-between;
  }

  .--grid-layout-menu-field {
    display: grid;
    grid-template-columns: (48% 26% 26%);
    width: 100%;
    justify-content: space-between;
  }

  .--grid-layout-menu-completed {
    display: grid;
    grid-template-columns: (25% 25% 50%);
    width: 100%;
    justify-content: space-between;
  }

  .arrow-greater-green {
    font-size: 25px;
    margin-top: -7px;
    color: #097504;
  }

  .arrow-lesser-red {
    font-size: 25px;
    margin-top: -7px;
    color: #d41a0d;
  }

  .arrow-equal-black {
    font-size: 25px;
    margin-top: -7px;
  }

  .--grid-layout-season-menu {
    display: grid;
    grid-template-columns: (39% 17% 8% 22% 14%);
    width: 100%;
    justify-content: space-between;
  }

  .--grid-padding-large {
    padding: 20px 20px 10px 00px;
  }

  .--grid-padding-extralarge {
    padding: 20px 20px 20px 35px;
  }

  .--grid-padding-extralarge-checkbox-heading {
    padding: 20px 20px 10px 35px !important;
  }

  .--grid-padding-extralarge-checkbox-heading-no-completed {
    padding: 35px 20px 10px 35px !important;
  }

  .--grid-padding-extralarge-commenting-checkbox {
    padding: 20px 5px 10px 35px;
  }

  .completed-text-align-right {
    text-align: right;
  }

  .--grid-content-padding-extralarge {
    padding: 15px 20px 15px 35px;
  }

  .--grid-padding-left {
    padding-left: 30px;
  }

  .--grid-padding-left-low {
    padding-left: 15px;
  }

  .--grid-padding-low {
    padding: 10px 20px 10px 0px;
  }

  .--border-thick-blue {
    border-bottom: 3px solid #bce2f2;
  }

  .--border-thin-blue {
    border-top: 1px solid #bce2f2;
  }

  .--border-thick-grey {
    border-bottom: 3px solid #e5e5e5;
  }

  .--border-thin-grey {
    border-top: 1px solid #e5e5e5;
  }
}

.fertilization-plan-width-fifty {
  width: 50%;
}

.fertilization-plan-width-ten {
  width: 10%;
}

.fertilizer-text-normal {
  font-size: $base-font-size;
  font-weight: $font-light;
}

.fertilizer-text-normal-light {
  font-size: 13px;
  font-weight: $font-light;
}

.padding-completed {
  padding-right: 12px;
  padding-top: 2px;
}

.fertilizer-text-large {
  font-size: 16px;
  font-weight: $font-light;
}

.fertilizer-padding-bottom {
  padding-bottom: 5px;
}

.fertilizer-padding-left {
  padding-left: 20px;
}

.fertilizer-padding-left-low {
  padding-left: 8px;
}

.fertilizer-align-center {
  align-self: center;
  padding-bottom: 20px;
}

.fertilizer-text-normal {
  font-size: 14px;
  font-weight: $font-light;
}

.error-message {
  float: left;
}

.empty-field-fertilizer {
  background-color: #f5f5f5;
  padding: 40px 30px 40px 30px;

  .empty-field-title {
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    color: #000;
    font-style: normal;
    margin-top: -10px;
    align-self: flex-start;
  }

  .empty-field-grid {
    display: grid;
    grid-template-columns: 42% 12% 23% 22%;
    //grid-template-columns: 42% 12% 45%;
    grid-gap: 1.5rem;
  }

  .empty-field-content {
    align-self: center;
    float: right;
    font-size: 0.75rem;
    font-weight: 300;
  }

  .empty-field-sub-content {
    align-self: center;
    float: right;
    font-size: 0.75rem;
    font-weight: 600;
  }

  .empty-field-button {
    align-self: center;
    float: right;
  }
}

.empty-field-sub-content ul li {
  list-style-type: disc !important;
}

.mineral-fertilizer-text-right {
  float: right;
}

.plan-list-edit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.plan-list-edit-button {
  float: right;
  margin-right: 35px;
  margin-top: 22px;
}

.plan-list-edit-button-border {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  cursor: pointer;
  border: thin solid rgb(42, 60, 99) !important;
  border-radius: 1.5rem;
  color: rgb(42, 60, 99) !important;
  padding: 0.275rem 1rem;
}

.plan-list-edit-button-border:hover {
  background-color: #e8f5fa;
}

@include break($tablet-screen) {
  .manure-grid-list {
    .flex-corner {
      margin: 15%;
    }
  }

  .grid-layout-farm-header {
    display: block;
    grid-template-columns: (100%);
    width: 100%;
  }

  .button-style-modal {
    float: inherit;
    padding-top: 100px;
  }

  .fertilizer-plan-list-header-calc__data {
    display: grid;
    grid: initial;
  }

  .fertilizer-plan-header-calc__data {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(13rem, 0fr));
    row-gap: 2rem;
    padding-top: 2rem;

    &.--edit {
      column-gap: 1rem;
    }
  }

  .fertilization-box {
    margin: 0px 12px 15px 15px;
    width: 95%;
  }

  .fertilization-plan-content {
    display: grid;
    grid: initial;
  }

  .empty-field-fertilizer {
    .empty-field-grid {
      display: grid;
      grid: initial;
    }
  }
}

.padding-right-class {
  padding-right: 20px;
  float: left;
}

.padding-right-class-float {
  float: left;
}

.padding-right-class-low {
  padding-right: 10px;
}

.padding-left-class-low {
  padding-left: 10px;
}

@include break($mobile-screen) {
  .add-fertilizer {
    margin-top: 0px;
  }

  .fertilization-plan-list-content-item_header {
    display: grid;
    grid: initial;

    .fertilization-plan-list-content-item_link {
      display: grid;
      grid: initial;
    }
  }

  .grid-layout-farm-header {
    display: block;
    grid-template-columns: (100%);
    width: 100%;
  }

  .fertilizer-plan-header-calc__data {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(13rem, 0fr));
    row-gap: 2rem;
    padding-top: 2rem;

    &.--edit {
      column-gap: 1rem;
    }
  }

  .padding-right-class {
    margin-top: 10px;
  }

  .padding-right-class-low {
    margin-top: 10px;
  }

  .plan-list-edit {
    display: grid;
    grid: initial;
  }

  .plan-list-edit-button {
    margin-right: 0px;
  }

  .manure-grid-list {
    display: grid;
    grid: initial;

    .flex-corner {
      margin: 15%;
    }
  }

  .fertilization-box {
    margin: 0px 12px 15px 15px;
    width: 90%;
  }

  .fertilizer-plan-list-header-calc__data {
    display: grid;
    grid: initial;
  }

  .fertilization-plan-content {
    display: grid;
    grid: initial;

    .--grid-layout {
      display: block;
      width: 100%;
    }

    .--grid-layout-menu {
      display: block;
      width: 100%;
    }

    .--grid-layout-menu-field {
      display: block;
      width: 100%;
    }

    .--grid-layout-menu-completed {
      display: block;
      width: 100%;
    }

    .--grid-layout-season-menu {
      display: block;
      width: 100%;
    }

    .--grid-padding-left {
      padding-left: 0px;
    }

    .--grid-padding-extralarge {
      padding: 15px 5px 15px 40px;
    }

    .--grid-content-padding-extralarge {
      padding: 15px 5px 15px 40px;
    }
  }

  .button-style .button-style-modal .button-style-yes {
    padding-right: 0px;
    float: inherit;
    padding-top: 20px;
  }

  .button-style-cancel {
    padding-left: 0px;
    float: inherit;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .empty-field-fertilizer {
    .empty-field-grid {
      display: grid;
      grid: initial;
    }
  }
}

.fertilization-header-div {
  /*margin-top: -20px;*/
}

.fertilization-header-div-high {
  /*margin-top: -50px;*/
}

.alert-cls-1 {
  fill: #fff;
}

.alert-icon-size {
  height: 3rem;
  width: 3rem;
}

.fetlization-menu-arrow {
  margin-top: 7px !important;
  text-align: center;
}

.fetlization-menu-arrow-padding {
  padding-right: 15px;
}

.input-textbox {
  box-sizing: border-box;
  width: 46%;
  border: 2px solid #e1e1e1;
  border-radius: 0.25rem;
  background-color: #fff;
  font-size: 1.0rem;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  height: 3rem;
  transition: box-shadow 0.2s ease-out;
}

.input-textbox-unit {
  font-size: 0.9rem;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  color: #000;
  padding-left: 5px;
}

.save-fertilization-plan {
  padding-bottom: 2rem;
}

.div-padding-left-high {
  padding-left: 30px;
}

.sample {
  background-color: lawngreen;
}

.text-align-center {
  text-align: center;
}

.select-box-menu {
  .css-11unzgr {
    max-height: 250px;
  }
}

.padding-top-low {
  padding-top: 8px;
}

.padding-top-low-textbox {
  padding-top: 8px;
}

.padding-top-low-textbox-left {
  padding-left: 15px !important;
}

.fixed-button {
  background-color: #f8f8f8;
  border-top: 1px solid #e7e7e7;
  padding: 10px 0px 20px 0px;
  position: sticky;
  bottom: 0;
  height: 60px;
  width: 100%;
  z-index: 1;
}

.fixed-button-popup {
  background-color: #e6e9e8;
  border-top: 1px solid #e7e7e7;
  position: sticky;
  bottom: 0;
  height: 60px;
  width: 100%;
  z-index: 1;
}

.fixed-button-mui {
  background-color: #ffffff;
  border-top: 1px solid;
  border-color: #dddddd;
  padding: 20px 0px 20px 0px;
  position: sticky;
  bottom: -17px;
  height: 60px;
  width: 100%;
}
.fixed-button-mui-mobile {
  background-color: #ffffff;
  border-top: 1px solid;
  border-color: #dddddd;
  padding: 20px 0px 20px 0px;
  position: sticky;
  bottom: 80px;
  height: 60px;
  width: 100%;
}

.fixed-button-mui-field {
  background-color: #ffffff;
  position: sticky;
  bottom: 0;
  width: 100%;
}

.text-lowercase {
  text-transform: lowercase;
}

.grid-layout-farm-header {
  padding: 20px 0px;
  display: grid;
  grid-template-columns: (17% 21% 21% 41%);
  width: 100%;
  justify-content: space-between;
}

.content-float-left {
  float: left;
}

.mineralization-calculation-div {
  text-align: left;
  padding-bottom: 20px;
}

.mineralization-calculation-div-less-bottom {
  text-align: left;
  padding-bottom: 10px;
  padding-right: 10px;
  float: left;
}

.water-font-size {
  font-size: 0.75rem;
  font-weight: 600;
}

.field-calculation-div-less-bottom {
  text-align: right;
  padding-bottom: 10px;
  float: right;
}

.field-calculation-padding-top {
  padding-top: 35px;
}

.button-style-yes-fertilization {
  text-align: center;
  padding-top: 50px;
}

.button-style-no-fertilization {
  float: left;
  padding-top: 20px;
  padding-left: 80px;
}

.missing-field-attributes {
  text-align: center;
  padding: 15px;
  font-weight: 600;
  font-size: medium;
}