.add-asset-container {
    text-align: center;
    margin: 156px 0;
}

.add-asset {
    width: 240px;
    margin: 0 auto;

    .add-form-icon {
        height: 50.4px;
        font-size: 58px;
        width: 58px;
    }

    .add-no-vehicle {
        margin-top: 10px;
        font-size: 20px;
        font-weight: 600;
    }

    .add-vehicle-message {
        margin-bottom: 15px;
    }

    .pointer-events-none {
        pointer-events: none;
    }

    .cursor-not-allowed {
        cursor: not-allowed;
    }
}

.asset-list-container {
    display: flex;
    height: auto;
}

.asset-list-content {
    position: relative;
    width: 100%;

    .add-vehicle {
        float: right;
        margin-top: 10px;
        cursor: pointer;

        .add-icon {
            display: inline-block;
            vertical-align: middle;
            color: $card-new-icon;

            svg {
                width: 23px;
                height: 23px;
            }
        }

        a {
            color: $general-text-color;
        }

        @include break($laptop-screen) {
            position: absolute;
            margin-top: 5px;
            right: 10px;
        }

        @include break($mobile-screen) {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            float: none;
            right: 0;
            top: 0;
            margin: 5px;
        }
    }

    .vehicle-list-details {
        .vehicle-list-wrapper {
            display: flex;
            flex-wrap: wrap;
            padding: 10px 16px 24px 16px;

            .list-item {
                .asset-details {
                    display: inline-flex;
                    flex-basis: 100%;
                    width: 100%;

                    .asset-model,
                    .asset-year,
                    .asset-arrow {
                        border-right: 1px solid $common-bdr-color;
                        padding: 0 25px;
                        width: 300px;
                        min-width: 300px;
                    }

                    .asset-arrow,
                    .asset-year {
                        align-self: center;
                        border-right: 0;
                    }

                    .asset-arrow {
                        flex: 1;
                    }

                    .add-vehicle-arrow {
                        transform: rotate(90deg);
                        font-size: 14px;
                        float: right;
                    }

                    .asset-model,
                    .asset-year {
                        .item-value {
                            font-weight: 300;
                        }
                    }

                    .asset-model {
                        .item-lbl {
                            font-weight: 600;
                            font-size: 14px;
                        }

                        span {
                            width: 100%;
                        }
                    }

                    @include break($laptop-screen) {
                        display: flex;
                        position: relative;

                        .asset-model,
                        .asset-year {
                            min-width: 200px;
                        }

                        .asset-arrow {
                            min-width: 100px;
                        }
                    }

                    @include break($mobile-screen) {

                        .asset-model,
                        .asset-year {
                            min-width: 170px;
                        }

                        .asset-arrow {
                            min-width: 100px;
                        }
                    }
                }

                .list-item-link {
                    border-left: 1px solid #aeaeae;
                    width: 100%;
                }
            }

            @include break($laptop-screen) {
                padding: 20px 10px;
            }
        }
    }

    .pad-right {
        padding-right: 5px;
    }

    .no-assets-message {
        margin: 30px 20px;
    }

    .add-asset-seperator {
        margin-top: 30px;

        @include break($mobile-screen) {
            margin-top: 0;
        }
    }

    .vehicle-list-flex-container>div {
        grid-template-columns: minmax(35rem, 1fr) 0 0;
        -ms-grid-columns: minmax(35rem, 1fr) 0 0;

        @include break($mobile-screen) {
            grid-template-columns: 100%;
            -ms-grid-columns: 0;
        }
    }

    .assets-header-section {
        @include break($mobile-screen) {
            min-height: 100px;
        }
    }
}


.add-asset-seperator {
    border-top: 10px solid $seperator-bg;

    @include break($mobile-screen) {
        margin-top: 0;
    }
}

.add-asset-wrapper {
    .add-vehicle-header {
        position: relative;

        a .update-vehicle-nav-link {
            color: $general-text-color;
            position: absolute;
            top: 25px;
        }

        .vehicle-icons-wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .vehicle-icon {
                padding: 0 10px;
                cursor: pointer;

                .delete-icon,
                .add-icon {
                    display: inline-block;
                    vertical-align: middle;
                }

                .add-icon {
                    color: $card-new-icon;
                    padding-right: 20px;

                    svg {
                        width: 23px;
                        height: 23px;
                    }
                }

                .delete-icon {
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }

                .disable-delete {
                    fill: $disable-color;
                }

                a {
                    color: $general-text-color;
                }
            }

            .pad-right {
                padding-right: 5px;
            }
        }
    }


}

.asset-list-wrapper {
    position: relative;
    min-height: 200px;
}

.add-asset-form {
    max-width: 390px;
    padding: 30px 20px 30px 40px;
    position: relative;

    .form-group {
        span.error-message {
            bottom: -5px;
        }
    }

    .form-group {
        .select-box-common.year {
            width: 50%;

            &+span.error-message {
                text-align: right;
                width: 50%;
                position: relative;
                float: left;
                clear: both;
            }
        }
    }

    .asset-approved-dates {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;

        &>div {
            width: 49%;
            max-width: 200px;
        }
    }

    .add-asset-buttons {
        display: flex;
        justify-content: flex-end;
        margin: 25px 0;

        button:first-child {
            margin-right: 20px;
        }
    }

    .calendar-container {
        width: 325px;
        height: auto;
        position: absolute;
        border: 1px solid $calendar-border;
        @include bdr-radius(2px);
        box-shadow: 1px 1px 5px $calendar-border;
        background: $calendar-bg;
        bottom: 200px;
        z-index: 10;
        right: 45px;

        .calendar-input {
            padding: 10px 5px;
        }
    }

    @include break($mobile-screen) {
        padding: 30px 15px;
    }
}